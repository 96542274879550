import React, { useState, useEffect } from "react";
import "./NewsLetter.css";
import { newsSubscribe, resetNews } from "../StateSlices/newsletterSlice";
import { useDispatch, useSelector } from "react-redux";

const NewsLetter = ({ toast, setSpinner, toastOption, spinner }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const { newsStatus, newsInfo, newsError } = useSelector(
    (state) => state.newsletter
  );

  useEffect(() => {
    setEmail("");
  }, []);

  const newsletterHandler = () => {
    console.log(email);
    if (email) {
      setSpinner(true);
      dispatch(newsSubscribe(email));
    } else {
      // console.log("Giving")
      toast.error("Please enter email address", toastOption);
    }
  };

  if (newsInfo) {
    let msg = newsInfo;
    dispatch(resetNews());
    setSpinner(false);
    if (msg.indexOf("Already") > -1) {
      toast.error(msg, toastOption);
    } else {
      toast.success(msg, toastOption);
    }
  }

  return (
    // <div className="news_letter_container">
    //   {/* <div className="refer_container">
    //       <h1 className="title">Refer A Friend - Earn A Free Hanger</h1>
    //       <div className="desc">Invite friends to join Closest Closet by entering their email addresses below. Earn a free hanger for everyone that signs up!</div>
    //       <div className="searchbar_container">
    //             <input type="text" className="input_search"/>
    //             <button className="btn"><i class="fas fa-envelope fa-lg"></i></button>
    //           </div>
    //     </div> */}
    //   <div className="subscribe_container">
    //     <h3 className="h3 white-text my-1">Closest Closet Newsletter</h3>
    //     <div className="desc h6">
    //       Stay up to date on Closest Closet news, your friends and product
    //       recommendations.
    //     </div>
    //     <div className="searchbar_container">
    //       <input
    //         type="email"
    //         required="true"
    //         className="input_search"
    //         value={email}
    //         onChange={(e) => setEmail(e.target.value)}
    //       />
    //       <button className="btn" onClick={newsletterHandler}>
    //         <i class="fas fa-envelope fa-lg"></i>
    //       </button>
    //     </div>
    //   </div>
    // </div>

    <div>
      <input
        type="email"
        placeholder="Your email address here"
        required={true}
        className="input_search"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        aria-label="Email Input"
      />
      <button
        onClick={newsletterHandler}
        className={`btn ${spinner ? "dis-btn" : ""}`}
        disabled={spinner ? true : false}
        
      >
        {spinner ? "Subscribing.." : "Subscribe"}
      </button>
    </div>
  );
};

export default NewsLetter;
