import React, { useEffect } from "react";
import "./Billing.css";
import hanger from "../../assets/images/hanger.svg";
import InjectedCheckoutSection from "../CardSection/CheckoutSection";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useFormikContext } from "formik";

// const stripePromise = loadStripe("pk_live_AEfhNK2o4rBBoKqhTFWMQe2N");

const stripePromise = loadStripe("pk_live_AEfhNK2o4rBBoKqhTFWMQe2N");

const Billing = ({
  userInfo,
  price,
  formRef,
  handleSubmit,
  handleChange,
  handleBlur,
  touched,
  errors,
  values,
  setId,
  handleCalculate,
  setFieldValue,
  shortHanger,
  spinner,
  setSpinner,
  ScrollToFieldError,
  addressDone,
  outOfStock,
}) => {
  // console.log(price)
  useEffect(() => {
    if (userInfo) {
      setFieldValue("email", userInfo.userDetailsId.userEmail);
      setFieldValue(
        "name",
        (userInfo.userDetailsId.userFName?userInfo.userDetailsId.userFName+" ":"") +
          (userInfo.userDetailsId.userLName?userInfo.userDetailsId.userLName:"")
      );
      console.log("VALUES",values)
      setFieldValue("address", userInfo.userDetailsId.userAddress);
      setFieldValue("city", userInfo.userDetailsId.userCity);
      setFieldValue("state", userInfo.userDetailsId.userState);
      setFieldValue("phone", userInfo.userDetailsId.userPhone);
      setFieldValue("zipCode", userInfo.userDetailsId.userZipCode);
    }
  }, [userInfo]);

  let done = [];
  let done1 = [];

  const { submitForm } = useFormikContext();
  return (
    <>
      {price.shipping ? (
        <div className="billing-container">
          <div className="title">Order Summary</div>
          <div className="amount-container">
            {/* <div className="amount-title">Order Summary</div> */}
            <div className="distribution">
              <div className="amount-heading">Total Hangers</div>
              <div className="amount">
                {price.hanger}{" "}
                <img
                  style={{ height: "15px" }}
                  src={hanger}
                  alt="hanger icon"
                />
              </div>
            </div>
            {shortHanger > 0 ? (
              <div
                className="hanger-shorts"
                style={{ textAlign: "center", color: "#F26654" }}
              >
                (Hangers Short {shortHanger})
              </div>
            ) : (
              ""
            )}
            {/* <div className="distribution">
                    <div className='amount-heading'>Your Hangers</div>
                    <div className='amount'>-{userInfo.userHanger} <img style={{height:"15px"}} src={hanger} alt="hanger icon"/></div>
                </div> */}
            {/* <hr/>
                <div className="distribution">
                    <div className='amount-heading'>Hangers to Pay</div>
                    <div className='amount'>{Math.abs(userInfo.userHanger-price.hanger)} <img style={{height:"15px"}} src={hanger} alt="hanger icon"/></div>
                </div> */}
            {shortHanger > 0 ? (
              <div className="distribution">
                <div className="amount-heading">Hangers Shortage Cost</div>
                <div className="amount">
                  {userInfo &&
                  userInfo.userDetailsId &&
                  userInfo.userDetailsId.membership != "trial" ? (
                    <>
                      <span style={{ textDecoration: "line-through" }}>
                        ${shortHanger * 7}
                      </span>{" "}
                      ${shortHanger * 5}
                    </>
                  ) : (
                    <>${shortHanger * 7}</>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            {shortHanger > 0 &&
            userInfo &&
            userInfo.userDetailsId &&
            userInfo.userDetailsId.membership != "trial" ? (
              <div style={{ textAlign: "center", color: "#F26654" }}>
                Discounted for paid members
              </div>
            ) : !userInfo ||
              (userInfo &&
                userInfo.userDetailsId &&
                userInfo.userDetailsId.membership == "trial") ? (
              <div style={{ textAlign: "center", color: "#F26654" }}>
                $7 per hanger credit for guest
              </div>
            ) : (
              ""
            )}

            <hr />
            <div className="distribution">
              <div className="amount-heading">Shipping Cost</div>
              <div className="amount">
                $
                {Object.values(price.shipping)
                  .reduce((accumulator, value) => {
                    // console.log(done);
                    if (done.includes(value[2])) {
                      return accumulator;
                    } else {
                      done.push(value[2]);
                      return accumulator + value[0];
                    }
                  }, 0)
                  .toFixed(2)}
              </div>
            </div>
            <div className="distribution">
              <div className="amount-heading">Sales Tax</div>
              <div className="amount">
                {price.salesTax.amount > 0
                  ? `(${price.salesTax.percent} %)`
                  : ""}{" "}
                ${price.salesTax.amount ? price.salesTax.amount : 0}{" "}
              </div>
            </div>
            <hr />
            <div className="distribution">
              <div className="amount-heading">Total Due</div>
              <div className="amount">
                $
                {(
                  price.price +
                  Object.values(price.shipping).reduce((accumulator, value) => {
                    if (done1.includes(value[2])) {
                      return accumulator;
                    } else {
                      done1.push(value[2]);
                      return accumulator + value[0];
                    }
                  }, 0) +
                  (price.salesTax.amount ? price.salesTax.amount : 0)
                ).toFixed(2)}
              </div>
            </div>
          </div>
          <div className="billing-details">
            <form onSubmit={handleSubmit}>
              <ScrollToFieldError />
              <div className="input_container">
                <label htmlFor="email_address" style={{ fontFamily: "LATO" }}>
                  Email Address
                </label>
                <input
                  type="email"
                  id="email_address"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  disabled={userInfo ? true : false}
                  className="lato-input"
                  placeholder="Email Address*"
                  required
                />
                {touched.email && errors.email ? (
                  <small className="form-text text-danger">
                    {errors.email}
                  </small>
                ) : null}
              </div>
              <div className="input_container" style={{ fontFamily: "LATO" }}>
                <label for="email_address">Shipping Address:</label>
                <input
                  type="text"
                  id="name"
                  placeholder="Full Name*"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  autoComplete="new-password"
                  className="lato-input"
                  required
                />
                {touched.name && errors.name ? (
                  <small className="form-text text-danger">{errors.name}</small>
                ) : null}
                <input
                  type="text"
                  id="address"
                  placeholder="Address*"
                  name="address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                  className="lato-input"
                  required
                />
                {touched.address && errors.address ? (
                  <small className="form-text text-danger">
                    {errors.address}
                  </small>
                ) : null}
                <input
                  type="text"
                  id="city"
                  placeholder="City*"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                  className="lato-input"
                  required
                />
                {touched.city && errors.city ? (
                  <small className="form-text text-danger">{errors.city}</small>
                ) : null}
                <input
                  type="text"
                  id="zipCode"
                  placeholder="Zip*"
                  name="zipCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.zipCode}
                  className="lato-input"
                  required
                />
                {touched.zipCode && errors.zipCode ? (
                  <small className="form-text text-danger">
                    {errors.zipCode}
                  </small>
                ) : null}
                <input
                  type="text"
                  id="state"
                  placeholder="State*"
                  name="state"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.state}
                  className="lato-input"
                  required
                />
                {touched.state && errors.state ? (
                  <small className="form-text text-danger">
                    {errors.state}
                  </small>
                ) : null}
                <input
                  type="text"
                  id="phone"
                  placeholder="Phone*"
                  name="phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  className="lato-input"
                  required
                />
                {touched.phone && errors.phone ? (
                  <small className="form-text text-danger">
                    {errors.phone}
                  </small>
                ) : null}
              </div>
              <button
                onClick={handleCalculate}
                style={{
                  marginTop: "20px",
                  fontWeight: "bold",
                  fontFamily: "LATO",
                  fontSize: "16px",
                }}
                type="button"
                className="btn dark-btn"
              >
                Update Shipping Address
              </button>
              <div style={{ marginTop: "21px" }}>
                {price.price +
                  Object.values(price.shipping).reduce((accumulator, value) => {
                    return accumulator + value[0];
                  }, 0) +
                  (price.salesTax.amount ? price.salesTax.amount : 0) >
                0 ? (
                  <Elements stripe={stripePromise}>
                    <InjectedCheckoutSection
                      submitForm={submitForm}
                      formRef={formRef}
                      setId={setId}
                      spinner={spinner}
                      errors={errors}
                      setSpinner={setSpinner}
                      addressDone={addressDone}
                    />
                  </Elements>
                ) : (
                  <button
                    style={{
                      margin: "20px 0px",
                      fontWeight: "bold",
                      fontFamily: "LATO",
                      fontSize: "16px",
                    }}
                    type="button"
                    disabled={(spinner===true || outOfStock) || addressDone===false ? true : false}
                    onClick={handleSubmit}
                    title={
                      outOfStock ? "One or More Items is out of Stock!" : ""
                    }
                    className={`btn dark-btn ${
                      addressDone === false || outOfStock ? "dis-btn" : ""
                    }`}
                  >
                    {spinner === true ? "Please Wait" : "Submit OrderLL"}
                  </button>
                )}
              </div>
              {/* <button className='btn dark-btn'>Pay</button> */}
            </form>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Billing;
