import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  resetUpdateStatus,
  cancelSubscription,
  pauseSubscription,
  getBrands,
  updateBrand,
  resetBrandError,
  deleteBrand,
  addBrand,
  getCategory,
  updateCategory,
  deleteCategory,
  addCategory,
} from "../../components/StateSlices/adminSlice";
import "./Category.css"

import Spinner1 from "../../components/Spinner1/Spinner1";
// import "./Brands.css";
import Modal from "react-modal";
import Select, { components } from "react-select";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import { FaPen, FaUndo } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";

import { MdOutlineCancel, MdSubscriptions } from "react-icons/md";
import "react-toastify/dist/ReactToastify.css";

import Pagination from "../../components/Pagination/Pagination";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingBottom: "3px",
  borderBottom: "2px solid #F26654",
};

const groupLabelStyles = {
  fontSize: "16px",
  fontWeight: "bold",
  color: "#F26654",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

function Category({ tab }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const {
    allUserStatus,
    allUsersInfo,
    allUserError,
    updateUserStatus,
    totalCount,
    allBrandInfo,
    brandErrorDisplay,
    allCategoryInfo,
    allCategorySize
  } = useSelector((state) => state.admin);

  const toastOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  // useEffect(() => {
  //   let token = localStorage.getItem("clttoken");
  //   if (token) {
  //     dispatch(getUsers({ token }));
  //     window.scrollTo(0, 0);
  //   } else {
  //     navigate("/404", { replace: true });
  //   }
  // }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#F26654" : "inherit",
      width: "100%",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#F26654",
      },
      fontSize: "14px",
      borderRadius: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      textTransform: "capitalize",
      marginTop: "3px",
      color: state.isSelected ? "#fff" : "#000",
      backgroundColor: state.isSelected ? "#F26654" : "#fff",
      "&:hover": {
        backgroundColor: state.isSelected
          ? "#F26654"
          : "rgba(242, 102, 84, 0.5)",
        color: "#fff",
      },
    }),
    // placeholder:(provided,state) => ({
    //   ...provided,
    //   textTransform: "capitalize",
    // }),
    singleValue: (provided, state) => ({
      ...provided,
      textTransform: "capitalize",
    }),
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [subModalIsOpen, setSubModalIsOpen] = useState(null);
  const [role, setRole] = useState(null);
  const [hangerCredits, setHangerCredits] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    location.search.slice(-1)
      ? location.search.slice(location.search.indexOf("=") + 1) - 1
      : 0
  );
  const [currentId, setCurrentId] = useState(null);
  const [currentUserName, setCurrentUserName] = useState(null);
  const [currentEmail, setCurrentEmail] = useState(null);
  const [userSearchVal, setUserSearchVal] = useState(null);
  const [pauseMonth, setPauseMonth] = useState({});
  const [currentCategory, setCurrentCategory] = useState(null);
  const [parentCategory, setParentCategory] = useState([]);
  const [parentCategorySize, setParentCategorySize] = useState([]);
  const [currentSize, setCurrentSize] = useState([]);
  const [categorySize, setCategorySize] = useState([])

  const subscriptionPauseOptions = [
    { label: "Pause for 1 Month", value: 1 },
    { label: "Pause for 2 Month", value: 2 },
    { label: "Pause for 3 Month", value: 3 },
  ];

  useEffect(()=>{
    if(allCategorySize){
      console.log(allCategoryInfo)
      let tempSize = allCategorySize.map(catg=>{
        return{
          label: catg.sizeName,
          value: catg.sizeName
        }
      })
      setCategorySize(tempSize)
    }
  },[allCategorySize])

  useEffect(() => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      //console.log(pageNumber);
      if (location.search.slice(-1)) {
        if (
          location.search.slice(location.search.indexOf("=") + 1) - 1 ==
          pageNumber
        ) {
          //console.log("THen this");
          dispatch(
            getCategory({
              token,
              p: location.search.slice(location.search.indexOf("=") + 1) - 1,
              search: userSearchVal,
            })
          );
          window.scrollTo(0, 0);
        } else {
          setPageNumber(
            location.search.slice(location.search.indexOf("=") + 1) - 1
          );
        }
      } else {
        //console.log("This");
        dispatch(getCategory({ token, p: pageNumber, search: userSearchVal }));
        window.scrollTo(0, 0);
      }
    } else {
      navigate("/404", { replace: true });
    }
  }, [pageNumber, location?.search?.slice(-1), tab]);

  const updateDetails = (e) => {
    e.preventDefault();
    if (currentId) {
      let token = localStorage.getItem("clttoken");
      if (token) {
        if (currentCategory) {
          if (parentCategory.length > 0) {
            let parent = parentCategory.map((pt) => {
              return pt.value == "Women"
                ? "1"
                : pt.value == "Men"
                ? "2"
                : pt.value == "Boy"
                ? "3"
                : pt.value == "Girl" ? "4" : pt.value == "Sports Equipment" ? "6": "5";
            });
            dispatch(
              updateCategory({
                token,
                id: currentId,
                payload: {
                  categoryName: currentCategory,
                  categoryParent: parent,
                  categoryParentSize: parentCategorySize
                },
                p: pageNumber,
                search: userSearchVal,
              })
            );
          } else {
            toast.error("Parent Category Cannot be Empty", toastOption);
          }
        } else {
          toast.error("Please Enter Valid Category Name", toastOption);
        }
      } else {
        navigate("/404", { replace: true });
      }
    } else {
      toast.error("Something went wrong!! Please Try Again", toastOption);
      setIsOpen(false);
    }
  };

  const categoryDelete = (id) => {
    swal({
      title: "Delete Category",
      text: "Once Deleted, this Category will not be visible to Users.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      //console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        if (token) {
          dispatch(
            deleteCategory({ token, id, p: pageNumber, search: userSearchVal })
          );
        } else {
          navigate("/404", { replace: true });
        }
      }
    });
  };

  const cancelUserSub = (id) => {
    swal({
      title: "Cancel User Subscription",
      text: "Once Deleted, User will be transferred to Guest User",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      //console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        if (token) {
          dispatch(
            cancelSubscription({
              token,
              id,
              p: pageNumber,
              search: userSearchVal,
            })
          );
        } else {
          navigate("/404", { replace: true });
        }
      }
    });
  };

  const pauseUserSub = (id, month) => {
    swal({
      title: "Pause User Subscription",
      text: `You want to Pause User Subscription for ${month} month`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      //console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        if (token) {
          dispatch(
            pauseSubscription({
              token,
              id,
              p: pageNumber,
              search: userSearchVal,
              month,
            })
          );
        } else {
          navigate("/404", { replace: true });
        }
      }
    });
  };

  const categoryAdd = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("clttoken");
    if (token) {
      if (currentCategory) {
        if (parentCategory.length > 0) {
          let parent = parentCategory.map((pt) => {
            return pt.value == "Women"
              ? "1"
              : pt.value == "Men"
              ? "2"
              : pt.value == "Boy"
              ? "3"
              : pt.value == "Girl" ? "4" : pt.value == "Sports Equipment" ? "6": "5";
          });
          dispatch(
            addCategory({
              token,
              payload: {
                categoryName: currentCategory,
                categoryParent: parent,
                categoryParentSize: parentCategorySize
              },
              p: pageNumber,
              search: userSearchVal,
            })
          );
        } else {
          toast.error("Parent Category Cannot be Empty", toastOption);
        }
      } else {
        toast.error("Please Enter Valid Category Name", toastOption);
      }
    } else {
      navigate("/404", { replace: true });
    }
  };

  if (updateUserStatus === "succeeded") {
    toast.success("Category Details Updated Successfully", toastOption);
    dispatch(resetBrandError());
    dispatch(resetUpdateStatus());
    setCurrentCategory(null);
    // setCurrentId(null);
    // setCurrentUserName(null);
    // setHangerCredits(0);
    // setRole(null);
    setIsOpen(false);
    setSubModalIsOpen({ display: false, user: null });
  }

  const resetSearch = () => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      // setPageNumber(0)
      navigate(`/admin/category?page=1`, { replace: true });
      if (location.search.slice(location.search.indexOf("=") + 1) - 1 == 0) {
        dispatch(getCategory({ token, p: 0, status: tab }));
      }
      //
    } else {
      navigate("/404", { replace: true });
    }
  };

  const category = [
    { label: "Women", value: "Women" },
    { label: "Men", value: "Men" },
    { label: "Boy", value: "Boy" },
    { label: "Girl", value: "Girl" },
    { label: "Sports Equipment", value: "Sports Equipment" },
  ];

  //   //console.log(allBrandInfo)
  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={subModalIsOpen?.display}
        onRequestClose={() => setSubModalIsOpen({ display: false, user: null })}
        style={{
          overlay: {
            background: "rgba(0,0,0,0.5)",
          },
          content: {
            maxWidth: "600px",
            maxHeight: "fit-content",
            minHeight: "250px",
            margin: "auto",
            position: "absolute",
          },
        }}
        contentLabel="Example Modal"
      >
        <div
          style={{
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            zIndex: 1,
            minHeight: "maxcontent",
          }}
        >
          <div className="terms-modal-con">
            <div className="terms-modal-wrapper">
              <h3>Add New Category</h3>
              <hr
                style={{
                  borderStyle: "solid",
                  borderColor: "var(--secondary-color)",
                  width: "50px",
                  borderWidth: "2px",
                  marginBottom: "10px",
                }}
              ></hr>
              <form onSubmit={(e) => categoryAdd(e)}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div
                    className=""
                    style={{
                      margin: "15px 10px 15px 0px",
                      display: "flex",
                      flexDirection: "column",
                      flex: 1 / 2,
                    }}
                  >
                    <label htmlFor="" className="item_title">
                      Category Name
                    </label>
                    <input
                      type="text"
                      id="userName"
                      name="userName"
                      onChange={(e) => setCurrentCategory(e.target.value)}
                      value={currentCategory}
                      // disabled={true}
                      style={{
                        padding: "9.5px",
                        //   cursor: "not-allowed",
                        //   background: "#eee",
                        fontSize: "14px",
                      }}
                    />
                  </div>
                  
                  <div
                    className=""
                    style={{
                      margin: "15px 10px 15px 0px",
                      display: "flex",
                      flexDirection: "column",
                      flex: 1 / 2,
                    }}
                  >
                    <label htmlFor="category" className="item_title">
                      Choose Category
                    </label>
                    <Select
                      options={category.sort((a, b) =>
                        a.label.localeCompare(b.label)
                      )}
                      isMulti
                      onChange={(value) => setParentCategory(value)}
                      value={parentCategory}
                      styles={customStyles}
                        // formatGroupLabel={formatGroupLabel}
                      //   components={{ MultiValueContainer }}
                    />
                  </div>
                  
                </div>
                <div>
                {/* <div
                  className=""
                  style={{
                    margin: "15px 10px 15px 0px",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1 / 2,
                  }}
                >
                  <label htmlFor="category" className="item_title">
                    Choose Size
                  </label>
                  <Select
                      options={[...categorySize].sort((a, b) => {
                        const parseSize = (size) => {
                          const match = size?.match(/^([0-9]*)([a-zA-Z]*)$/);
                          if (!match) {
                            return { number: 0, suffix: size || "" }; // Default to 0 for numbers and the full string as the suffix
                          }
                          return {
                            number: match[1] ? parseInt(match[1], 10) : 0, // Extract numeric part
                            suffix: match[2] || "", // Extract suffix part
                          };
                        };
                    
                        const sizeA = parseSize(a.label);
                        const sizeB = parseSize(b.label);
                    
                        if (sizeA.suffix === sizeB.suffix) {
                          // If suffixes are the same, compare numbers
                          return sizeA.number - sizeB.number;
                        }
                        // Sort suffixes alphabetically if numbers are not comparable
                        return sizeA.suffix.localeCompare(sizeB.suffix);
                      })}
                    
                    isMulti
                    //   onChange={(value) => formik.setFieldValue("category", value)}
                    onChange={(value) => {
                      //console.log(parentCategory);
                      //console.log(currentCategory);
                      // setParentCategory(value);
                      setParentCategorySize(value)
                    }}
                    value={parentCategorySize}
                    styles={customStyles}
                    formatGroupLabel={formatGroupLabel}
                    componaents={{ MultiValueContainer }}
                  />
                </div> */}

                <div
                  className=""
                  style={{
                    margin: "15px 10px 15px 0px",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1 / 2,
                  }}
                >
                  <label htmlFor="category" className="item_title">
                    Choose Size
                  </label>
                  <div
                       style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px", // Add spacing between checkboxes
                        maxHeight: "400px", // Set a maximum height for the container
                        overflowY: "auto", // Enable vertical scrolling
                        border: "1px solid #ccc", // Optional: Add a border for better visibility
                        padding: "10px", // Optional: Add padding inside the scrollable container
                        borderRadius: "4px", // Optional: Add rounded corners
                      }}
                  >
                    {[...categorySize]
                      // .sort((a, b) => {
                      //   const parseSize = (size) => {
                      //     const match = size?.match(/^([0-9]*)([a-zA-Z]*)$/);
                      //     if (!match) {
                      //       return { number: 0, suffix: size || "" }; // Default to 0 for numbers and the full string as the suffix
                      //     }
                      //     return {
                      //       number: match[1] ? parseInt(match[1], 10) : 0, // Extract numeric part
                      //       suffix: match[2] || "", // Extract suffix part
                      //     };
                      //   };

                      //   const sizeA = parseSize(a.label);
                      //   const sizeB = parseSize(b.label);

                      //   if (sizeA.suffix === sizeB.suffix) {
                      //     // If suffixes are the same, compare numbers
                      //     return sizeA.number - sizeB.number;
                      //   }
                      //   // Sort suffixes alphabetically if numbers are not comparable
                      //   return sizeA.suffix.localeCompare(sizeB.suffix);
                      // })
                      .map((option) => (
                        <label key={option.value} style={{ display: "flex", alignItems: "center" }}>
                          <input
                            type="checkbox"
                            value={option.value}
                            checked={parentCategorySize.some((size) => size.value === option.value)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setParentCategorySize([...parentCategorySize, option]);
                              } else {
                                setParentCategorySize(
                                  parentCategorySize.filter((size) => size.value !== option.value)
                                );
                              }
                            }}
                          />
                          <span style={{ marginLeft: "8px" }}>{option.label}</span>
                        </label>
                      ))}
                  </div>
                </div>


                </div>
                <div
                  className="brand_error"
                  style={{ color: "red", marginBottom: "10px" }}
                >
                  {brandErrorDisplay}
                </div>
                <button
                  className="btn dark-btn"
                  type="submit"
                  style={{ borderRadius: "2px" }}
                >
                  Add Category
                </button>
              </form>
            </div>
          </div>

          {/* <br />
          <br />
          <br /> */}
          {/* <div className="h5 bold-text background-text">
            Cancel Subscription
          </div> */}
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={{
          overlay: {
            background: "rgba(0,0,0,0.5)",
          },
          content: {
            maxWidth: "600px",
            maxHeight: "fit-content",
            margin: "auto",
            position: "absolute",
          },
        }}
        contentLabel="Example Modal"
      >
        <div
          className="close_container"
          style={{ display: "flex", justifyContent: "end" }}
        >
          {/* <button
            className="btn dark-btn"
            style={{ padding: "4px 9.5px", borderRadius: "2px" }}
            onClick={() => this.setState({ modalIsOpen: false })}
          >
            X
          </button> */}
        </div>

        <div className="terms-modal-con">
          <div className="terms-modal-wrapper">
            <h3>Category Edit</h3>
            <hr
              style={{
                borderStyle: "solid",
                borderColor: "var(--secondary-color)",
                width: "50px",
                borderWidth: "2px",
                marginBottom: "10px",
              }}
            ></hr>
            <form onSubmit={(e) => updateDetails(e)}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div
                  className=""
                  style={{
                    margin: "15px 10px 15px 0px",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1 / 2,
                  }}
                >
                  <label htmlFor="" className="item_title">
                    Category Name
                  </label>
                  <input
                    type="text"
                    id="userName"
                    name="userName"
                    onChange={(e) => setCurrentCategory(e.target.value)}
                    value={currentCategory}
                    // disabled={true}
                    style={{
                      padding: "9.5px",
                      //   cursor: "not-allowed",
                      //   background: "#eee",
                      fontSize: "14px",
                    }}
                  />
                </div>
                <div
                  className=""
                  style={{
                    margin: "15px 10px 15px 0px",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1 / 2,
                  }}
                >
                  <label htmlFor="category" className="item_title">
                    Choose Category
                  </label>
                  <Select
                    options={category.sort((a, b) =>
                      a.label.localeCompare(b.label)
                    )}
                    isMulti
                    onChange={(value) => setParentCategory(value)}
                    value={parentCategory}
                    styles={customStyles}
                    //   formatGroupLabel={formatGroupLabel}
                    //   components={{ MultiValueContainer }}
                  />
                </div>
              </div>
              <div>
              <div
                  className=""
                  style={{
                    margin: "15px 10px 15px 0px",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1 / 2,
                  }}
                >
                  <label htmlFor="category" className="item_title">
                    Choose Size
                  </label>
                  <div
                       style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px", // Add spacing between checkboxes
                        maxHeight: "400px", // Set a maximum height for the container
                        overflowY: "auto", // Enable vertical scrolling
                        border: "1px solid #ccc", // Optional: Add a border for better visibility
                        padding: "10px", // Optional: Add padding inside the scrollable container
                        borderRadius: "4px", // Optional: Add rounded corners
                      }}
                  >
                    {[...categorySize]
                      // .sort((a, b) => {
                      //   const parseSize = (size) => {
                      //     const match = size?.match(/^([0-9]*)([a-zA-Z]*)$/);
                      //     if (!match) {
                      //       return { number: 0, suffix: size || "" }; // Default to 0 for numbers and the full string as the suffix
                      //     }
                      //     return {
                      //       number: match[1] ? parseInt(match[1], 10) : 0, // Extract numeric part
                      //       suffix: match[2] || "", // Extract suffix part
                      //     };
                      //   };

                      //   const sizeA = parseSize(a.label);
                      //   const sizeB = parseSize(b.label);

                      //   if (sizeA.suffix === sizeB.suffix) {
                      //     // If suffixes are the same, compare numbers
                      //     return sizeA.number - sizeB.number;
                      //   }
                      //   // Sort suffixes alphabetically if numbers are not comparable
                      //   return sizeA.suffix.localeCompare(sizeB.suffix);
                      // })
                      .map((option) => (
                        <label key={option.value} style={{ display: "flex", alignItems: "center" }}>
                          <input
                            type="checkbox"
                            value={option.value}
                            checked={parentCategorySize.some((size) => size.value === option.value)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setParentCategorySize([...parentCategorySize, option]);
                              } else {
                                setParentCategorySize(
                                  parentCategorySize.filter((size) => size.value !== option.value)
                                );
                              }
                            }}
                          />
                          <span style={{ marginLeft: "8px" }}>{option.label}</span>
                        </label>
                      ))}
                  </div>
                </div>
                </div>
              <div
                className="brand_error"
                style={{ color: "red", marginBottom: "10px" }}
              >
                {brandErrorDisplay}
              </div>
              <button
                className="btn dark-btn"
                type="submit"
                style={{ borderRadius: "2px" }}
              >
                Update Category
              </button>
            </form>
          </div>
        </div>
      </Modal>
      <div className="users-header">
        <div
          className="h2 background-text bold-text"
          style={{ minWidth: "30%", marginBottom: "1rem" }}
        >
          Manage Category
        </div>
        <div className="input_container search_closet">
          <form
            className="input_container_wrapper"
            style={{ gap: 0 }}
            onSubmit={(e) => {
              e.preventDefault();
              let token = localStorage.getItem("clttoken");
              if (token) {
                // setPageNumber(0)
                navigate(`/admin/category?page=1`, { replace: true });
                if (
                  location.search.slice(-1) &&
                  location.search.slice(location.search.indexOf("=") + 1) - 1 ==
                    0
                ) {
                  dispatch(
                    getCategory({
                      token,
                      p: 0,
                      search: userSearchVal,
                    })
                  );
                }
              } else {
                navigate("/404", { replace: true });
              }
            }}
          >
            <div style={{ display: "flex", flex: 1 }}>
              <input
                type="text"
                id="userSearch"
                name="userSearch"
                onChange={(e) => setUserSearchVal(e.target.value)}
                value={userSearchVal}
                placeholder="Enter Category Name"
                style={{
                  marginTop: "0px",
                  fontFamily: "inherit",
                  padding: "10px 12px",
                }}
              />
              <button
                type="submit"
                className="btn"
                style={{ borderRadius: "0 5px 5px 0" }}
              >
                <i class="fas fa-search fa-lg"></i>
              </button>
            </div>
            <div>
              {userSearchVal && (
                <span
                  className="small-text"
                  style={{
                    marginRight: 0,
                    // float: "right",
                    marginTop: "5px",
                    cursor: "pointer",
                    color: "var(--background-color)",
                    fontSize: "13px",
                    fontFamily: "LATO",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    resetSearch();
                    setUserSearchVal("");
                  }}
                >
                  Reset <FaUndo style={{ fontSize: "11px" }} />
                </span>
              )}
            </div>
          </form>
        </div>
      </div>

      <div className="users">
        <div className="add-new-brand-btn-con">
          <button
            className="btn"
            style={{
              padding: "10px 12px",
              fontSize: "16px",
              borderRadius: "5px",
            }}
            onClick={() => {
              setSubModalIsOpen({
                display: true,
              });
              setParentCategorySize([])
            }}
          >
            Add New Category
          </button>
        </div>

        <table className="users-table">
          {allUserStatus === "loading" ? (
            <Spinner1 />
          ) : (
            <>
              {allCategoryInfo && allCategoryInfo.length === 0 ? (
                <div
                  style={{ marginTop: "1rem", marginBottom: "4rem" }}
                  className="no_item"
                >
                  <h1 className="h1" style={{ marginTop: "1rem" }}>
                    No Category Found
                  </h1>
                </div>
              ) : (
                <>
                  <thead>
                    <tr>
                      <th
                        className="h4"
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        Sr. No
                      </th>
                      <th
                        className="h4"
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        Category
                      </th>
                      <th
                        className="h4"
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        Parent Category
                      </th>
                      <th
                        className="h4"
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        Sizes
                      </th>
                      <th
                        className="h4"
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCategoryInfo &&
                      allCategoryInfo.length > 0 &&
                      allCategoryInfo.map((ctg, index) => {
                        return (
                          <tr key={index}>
                            <td
                              className="h5"
                              style={{ lineBreak: "anywhere" }}
                            >
                              {pageNumber * 20 + index + 1}
                            </td>
                            <td
                              className="h5"
                              style={{ lineBreak: "anywhere" }}
                            >
                              {ctg.categoryName}
                            </td>
                            <td
                              className="h5"
                              style={{ lineBreak: "anywhere" }}
                            >
                              {ctg.categoryParent.length > 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                  }}
                                >
                                  {ctg.categoryParent.map((parent, index) => {
                                    return (
                                      <div class="badge_parent">
                                        {parent == "1"
                                          ? "Women"
                                          : parent == "2"
                                          ? "Men"
                                          : parent == "3"
                                          ? "Boy"
                                          : parent == "4" ? "Girl" : parent == "5" ? "Other" :"Sports Equipment"}
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                "No Parent Category"
                              )}
                            </td>

                            <td
                              className="h5 category-width"
                              style={{ lineBreak: "anywhere" }}
                            >
                              {ctg.sizes.length > 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                  }}
                                >
                                  {ctg.sizes.map((parent, index) => {
                                    return (
                                      <div class="badge_parent">
                                        {
                                          parent?.sizeName
                                        }
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                "No Sizes"
                              )}
                            </td>

                            <td className="h5 action-width" >
                              <button
                                title="Edit Category"
                                style={{
                                  borderRadius: "2px",
                                  background: "transparent",
                                  color: "var(--primary-color)",
                                  padding: "5px",
                                }}
                                onClick={() => {
                                  setCurrentId(ctg._id);
                                  setCurrentCategory(ctg.categoryName);
                                  let tmp = ctg.categoryParent.map((parent) => {
                                    return {
                                      label:
                                        parent == "1"
                                          ? "Women"
                                          : parent == "2"
                                          ? "Men"
                                          : parent == "3"
                                          ? "Boy"
                                          : parent == "4" ? "Girl" : parent == "5" ? "Other" :"Sports Equipment",
                                      value:
                                        parent == "1"
                                          ? "Women"
                                          : parent == "2"
                                          ? "Men"
                                          : parent == "3"
                                          ? "Boy"
                                          : parent == "4" ? "Girl" : parent == "5" ? "Other" : "Sports Equipment",
                                    };
                                  });

                                  let tmpSize = ctg.sizes.map(cat=>{
                                    return {
                                      label: cat.sizeName,
                                      value: cat.sizeName
                                      }
                                  })
                                  setParentCategorySize(tmpSize);
                                  setParentCategory(tmp);
                                  setIsOpen((prev) => !prev);
                                }}
                              >
                                <FaPen size={"1.4em"} />
                              </button>
                              <button
                                title="Delete Category"
                                style={{
                                  borderRadius: "2px",
                                  background: "transparent",
                                  color: "var(--primary-color)",
                                  padding: "5px",
                                }}
                                onClick={() => {
                                  categoryDelete(ctg._id);
                                }}
                              >
                                <AiFillDelete size={"1.8em"} />
                              </button>
                            </td>
                            {/* </td> */}
                          </tr>
                        );
                      })}
                  </tbody>
                </>
              )}
            </>
          )}
        </table>

        {totalCount > 20 && (
          <Pagination
            pageCount={Math.ceil(totalCount / 20)}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        )}
      </div>
    </>
  );
}

const formatGroupLabel = (data) => {
  console.log(data)
  return (
  <div style={groupStyles}>
    <span style={groupLabelStyles}>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)};

const MultiValueContainer = (value) => {
  let ct =
    value.data && value.data["value"].slice(value.data["value"].length - 1);
  return (
    value.data && (
      <div style={{ display: "flex", alignItems: "center", margin: "5px" }}>
        <div>
          {ct === "M"
            ? "🧔‍♂️"
            : ct === "W"
            ? "👩"
            : ct === "B"
            ? "👦"
            : ct === "G"
            ? "👧"
            : ct === "S"?"🏅":"⭐️"}
        </div>
        <components.MultiValueContainer {...value} />
      </div>
    )
  );
};

export default Category;
