import React from "react";
import "./InformationSection.css";
import { BsTreeFill, BsDropletHalf } from "react-icons/bs";
import { GiMineTruck, GiClothes, GiAlienFire, GiDolphin } from "react-icons/gi";
import { IoEarth } from "react-icons/io5";
import { ImFire } from "react-icons/im";
import { DiDigitalOcean } from "react-icons/di";
import CountUp from "react-countup";
import { AiFillFire } from "react-icons/ai";

const InformationSection = () => {
  const data = [
    {
      icon: <BsTreeFill size={"2em"} />,
      suffix: " Million",
      number: "70",
      desc: "trees are cut down each year for clothing.",
      hyperlink:
        "https://www.forbes.com/sites/jamesconca/2015/12/03/making-climate-change-fashionable-the-garment-industry-takes-on-global-warming/?sh=4d1b554679e4",
    },
    {
      icon: <BsDropletHalf size={"2em"} />,
      suffix: " Tons",
      number: "200",
      desc: "of fresh water are needed to dye one ton of fabric.",
      hyperlink: "https://www.sustainyourstyle.org/en/environmental-impacts",
    },
    {
      icon: <GiMineTruck size={"2em"} />,
      suffix: " Garbage Truck of Textiles",
      number: "1",
      desc: "is landfilled or incinerated every second.",
      hyperlink:
        "https://www.carpetcycle.com/news/80/the-ultimate-fashion-fail-one-garbage-truck-of-textiles-is-thrown-away-every-second/#:~:text=%22The%20textiles%20industry%20is%20a,second%20of%20the%20the%20year.",
    },
    {
      icon: <GiClothes size={"2em"} />,
      suffix: "%",
      number: "40 ",
      desc: "more clothing is produced than 20 years ago",
      hyperlink:
        "https://www.bloomberg.com/graphics/2022-fashion-industry-environmental-impact/?leadSource=uverify%20wall",
    },
    {
      icon: <GiDolphin size={"1.8em"} style={{ transform: "scaleX(-1)" }} />,
      suffix: "k lbs",
      number: "190",
      desc: "of textile microfibers end up in the oceans every year",
      hyperlink:
        "https://greenbusinessbureau.com/industries/fashion/what-is-toxic-fast-fashion-and-how-does-it-impact-the-environment/",
    },
    {
      icon: (
        <>
          <AiFillFire size={"2.5em"} style={{ position: "absolute" }} />
          <IoEarth size={"1.2em"} className="earth" />
        </>
      ),
      suffix: " Million Tons",
      number: "108",
      desc: "of non-renewable resources are used each year to produce clothing",
      hyperlink:
        "https://conversableeconomist.blogspot.com/2018/01/textiles-your-clothes-are-pollutants.html",
    },
  ];
  return (
    <section>
      <h2
        className="large-text bold-text text-center background-text"
        style={{ margin: "1rem" }}
      >
        Make Sustainability a Daily Priority
      </h2>{" "}
      <h3
        className="h3 desc lato-desc"
        style={{
          margin: 0,
          marginBottom: "2rem",
          textAlign: "center",
        }}
      >
        Secondhand clothing is the future of fashion. <br />
        Extend the life of fashion, and reduce your impact on the planet!
      </h3>
      <div className="row">
        <br />
        {data.map((item, index) => (
          <>
            <div class="s-column" key={index}>
              <div class="service-card">
                {/* <div className="information-index">{`0${index + 1}`}</div> */}
                <div class="icon-wrapper">{item.icon}</div>
                <CountUp
                  
                  
                  end={item.number}
                  start={0}
                  scrollSpyOnce={true}
                  enableScrollSpy={true}
                  useEasing={true}
                  delay={2}
                  suffix={item.suffix}
                  duration={8}
                  separator=" "
                >
                  {({ countUpRef }) => (
      <div>
        <span className="h2 bold-text" ref={countUpRef} role="heading" aria-level="2"/>
      </div>
  )}
  </CountUp>
                <p className="h5" style={{ margin: "0", marginBottom: "1rem" }}>
                  {item.desc}
                </p>
              </div>
            </div>
          </>
        ))}
      </div>
    </section>
  );
};

export default InformationSection;
