import React, { useState } from "react";
import "./VideoSection.css";
import videoBG from "../../assets/images/videoBG.jpg";
const VideoSection = () => {
  const [hideBg, setHideBg] = useState(true);
  return (
    <section>
      <h2
        className="large-text bold-text text-center background-text"
        style={{ margin: "1rem" }}
      >
        Closest Closet On Good Day Chicago
      </h2>
      <div className="video-con">
        <div class="sc_video_inner">
          <img
            class="video_bg"
            src={videoBG}
            alt="video-cc"
            style={
              hideBg ? { zIndex: 1, opacity: 1 } : { zIndex: 0, opacity: 0 }
            }
          />
          <div
            class="v_icon popup_youtube"
            style={
              hideBg ? { zIndex: 1, opacity: 1 } : { opacity: 0, zIndex: 0 }
            }
            onClick={() => {
              //   //consolele.log("clicked");
              setHideBg((prev) => !prev);
              //consolele.log(hideBg);
            }}
          >
            <span class="dot_animation"></span>
            <img
              src="https://cdn.pixabay.com/photo/2016/02/01/12/33/play-1173551__340.png"
              alt="dot-animation"
              tabIndex={0}
              style={{ width: "70px", marginTop: ".4rem", cursor: "pointer" }}
            />
          </div>
          <iframe
            style={hideBg ? { opacity: 0 } : { opacity: 1 }}
            width="560"
            height="315"
            src="https://www.youtube.com/embed/PoHOp6I6Y-0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default VideoSection;
