import React, { useEffect, useState } from "react";
import "./Order.css";
import hanger from "../../assets/images/hanger.svg";
import FP1 from "../../assets/images/FP1.png";
import SaveTreeIcon from "../../assets/images/save-tree.png";
import Collapse, { Panel } from "rc-collapse";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";

const Order = ({
  products,
  setPrice,
  removekey,
  shippingInfo,
  shippingErrorPlace,
  removeSessionKey,
  userInfo,
  shippingChange,
  getSalesTax,
  dispatch,
  fullPrice,
  formRef,
  taxReset,
  cartOutOfStock,
}) => {
  const bestShippingOptions = [];
  // if(shippingInfo)
  // shippingInfo[0].shipDetails.map((item) =>
  //   bestShippingOptions.push(item.shippingOption[0])
  // );
  //consolele.log("k2l", shippingInfo,products,cartOutOfStock );
  useEffect(() => {
    // if (shippingInfo) {
    //   shippingInfo[0].shipDetails.map((item) =>
    //     bestShippingOptions.push(item.shippingOption[0])
    //   );
    // }
  }, [shippingInfo]);
  //consolele.log(bestShippingOptions);
  const shippingSelect = async (product, type, shipid, shipment, price) => {
    let ship = { ...fullPrice };
    let fullproductList = await products.flat().map((produ) => {
      // //consolele.log(produ)
      return {
        productId: produ._id,
        hanger: produ.productHanger,
        closetId: produ.closetId,
        shipPrice: product.some((item) => item._id === produ._id)
          ? price
          : fullPrice.shipping[produ._id][0],
      };
    });
    let ship2 = await product.map((prod, index) => {
      //consolele.log(product);
      setPrice((prevPrice) => {
        let temp = { ...prevPrice };
        ship.shipping[prod._id] = [price, type, shipid, shipment];
        temp.shipping[prod._id] = [price, type, shipid, shipment];
        return temp;
      });
    });
    //consolele.log("Ship", ship2)
    let toAddress = formRef.current.values;
    dispatch(taxReset());
    let token = localStorage.getItem("clttoken");
    dispatch(
      getSalesTax({
        shippingData: { fullproductList, toAddress },
        token,
        id: ship.salesTax ? ship.salesTax.taxId : 0,
      })
    );
  };

  const [collapsed, setCollapsed] = useState([]);
  const [contentHeight, setContentHeight] = useState([]);

  const toggleCollapse = (index) => {
    //consolele.log("jk", index, collapsed);
    // let temp = collapsed
    // temp[index] = temp[index]?!temp[index]:true
    setCollapsed((prevState) => {
      let temp = [...prevState];
      // temp["unique"] = true;
      // if (index === "unique") {
      //   if (temp[index] === true) temp["unique"] = false;
      //   else temp["unique"] = true;
      //   //consolele.log(temp["unique"]);
      // } else {
      // }
      temp[index] = temp[index] ? !temp[index] : true;
      //consolele.log(temp, "TEMP");
      return temp;
    });
  };

  const contentRef = React.useRef([]);

  React.useLayoutEffect(
    (i) => {
      //consolele.log(contentRef.current);
      // setContentHeight(contentRef.current[i].scrollHeight);
      const newContentHeight = contentRef.current.map(
        (ref) => ref.scrollHeight
      );
      //consolele.log("kkk", contentRef.current, newContentHeight);
      setContentHeight(newContentHeight);
    },
    [collapsed]
  );

  return (
    <div className="order-container">
      <div className="title">My Bag</div>
      <div className="orders">
        {products.length > 0 &&
          products.map((prod, key) => {
            return (
              <div className="order-card-container" key={key}>
                {prod.map((product, index) => {
                  return (
                    <div className="order-card">
                      <div
                        className="cross"
                        onClick={() => {
                          if (userInfo) {
                            removekey(product._id);
                          } else {
                            removeSessionKey(product._id);
                          }
                        }}
                      >
                        X
                      </div>

                      <div className="product-img-container">
                        <img
                          src={product.productImage[0]}
                          width="100%"
                          alt={product.productName}
                        />
                      </div>
                      <div className="order-desc-container">
                        <div className="order-meta-container">
                          <div className="product-title-container">
                            <div>Item</div>
                            <h4 className="product-title">
                              {product.productName}
                            </h4>
                            {cartOutOfStock.find(
                              (cart) => cart.id === product._id
                            ) && (
                              <div
                                className="out-of-stock"
                                style={{ color: "red" }}
                              >
                                <span>Out of Stock</span>
                              </div>
                            )}
                            <h6>
                              <span className="bold-text">Brand :</span>
                              {product.productStats.brand}
                            </h6>
                            <h6>
                              <span className="bold-text">Color :</span>
                              {product.productStats.color[0]}
                            </h6>
                            <h6>
                              <span className="bold-text">Condition :</span>
                              {product.productStats.condition}
                            </h6>
                            <h6>
                              <span className="bold-text">Size :</span>
                              {product.productStats.size}
                            </h6>
                          </div>
                          <div className="product-price-container">
                            <div className="product-price-title">
                              Hanger Credit
                            </div>
                            <div className="product-price">
                              {product.productHanger}{" "}
                              <img
                                style={{ height: "18px" }}
                                src={hanger}
                                alt="hanger icon"
                              />{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="sharedby-delivery-con">
                  <div className="closet-detail-container">
                    <div className="closet-title">Shared By</div>
                    <div className="closet-icon">
                      <img
                        src={
                          prod[0].closetIcon
                            ? prod[0].closetIcon
                            : "https://www.instyle.com/thmb/ciGuAwe4dfMdkFBuaGwnavsRSaw=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/closet-lead-2000-5b8bd03a1a6044aeb146a92b5e52d24c.jpg"
                        }
                        alt="closet icon"
                      />
                    </div>
                    <div className="closet-name">{prod[0].closetName}</div>
                  </div>

                  <div className="delivery-container">
                    <div className="delivery-title">
                      Choose Pickup / Delivery
                    </div>
                    <div className="delivery-options">
                      <div className="option">
                        <input
                          type="radio"
                          value="free"
                          name={`shipping${prod[0]._id}`}
                          onChange={() => {
                            shippingSelect(prod, "free", 0, 0, 0);
                            shippingChange();
                          }}
                          // checked={fullPrice.shipping[prod[0]._id][0]===0?true:false}
                          defaultChecked
                        />
                        <label>
                          <img
                            src={SaveTreeIcon}
                            width="30px"
                            alt="save_tree_icon"
                          />
                          &nbsp;&nbsp;FREE Local Pickup{" "}
                          {shippingInfo &&
                            shippingInfo[
                              shippingInfo.findIndex(
                                (x) => x.productId === prod[0]._id
                              )
                            ] !== undefined &&
                            shippingInfo[
                              shippingInfo.findIndex(
                                (x) => x.productId === prod[0]._id
                              )
                            ].localPickup && (
                              <span
                                style={
                                  shippingInfo &&
                                  shippingInfo[
                                    shippingInfo.findIndex(
                                      (x) => x.productId === prod[0]._id
                                    )
                                  ] !== undefined &&
                                  shippingInfo[
                                    shippingInfo.findIndex(
                                      (x) => x.productId === prod[0]._id
                                    )
                                  ].localPickup > 25
                                    ? { color: "red" }
                                    : { color: "green" }
                                }
                              >
                                (
                                {shippingInfo &&
                                  shippingInfo[
                                    shippingInfo.findIndex(
                                      (x) => x.productId === prod[0]._id
                                    )
                                  ] !== undefined &&
                                  shippingInfo[
                                    shippingInfo.findIndex(
                                      (x) => x.productId === prod[0]._id
                                    )
                                  ].localPickup}{" "}
                                miles)
                              </span>
                            )}
                          <br />
                          &nbsp;&nbsp;
                          {userInfo
                            ? "(" + prod[0].city + " )"
                            : "( Please Sign In to view Pickup Address )"}
                        </label>
                      </div>
                      {shippingInfo == null ? (
                        <p>
                          Please enter your address and press calculate to see
                          shipping options.
                        </p>
                      ) : shippingInfo[
                          shippingInfo.findIndex(
                            (x) => x.productId === prod[0]._id
                          )
                        ] !== undefined &&
                        (shippingInfo[
                          shippingInfo.findIndex(
                            (x) => x.productId === prod[0]._id
                          )
                        ].shipDetails.length === 0 ||
                          prod.some((prod) => {
                            return prod.productShipping === false;
                          })) ? (
                        <p>
                          No Shipping Option are available for this address.
                        </p>
                      ) : (
                        <>
                          <div>
                            {shippingInfo[
                              shippingInfo.findIndex(
                                (x) => x.productId === prod[0]._id
                              )
                            ] !== undefined &&
                              shippingInfo[
                                shippingInfo.findIndex(
                                  (x) => x.productId === prod[0]._id
                                )
                              ].shipDetails.map((ship, index) => {
                                return (
                                  <>
                                    <div>
                                      {ship.shippingOption.map(
                                        (shipPrice, key) => {
                                          if (key === 0) {
                                            return (
                                              <>
                                                <div className="option" style={{margin: "1.5rem 0"}}>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <input
                                                      id={shipPrice.id}
                                                      type="radio"
                                                      value={ship.provider}
                                                      style={{
                                                        marginRight: "5px",
                                                        display: "inline-block",
                                                      }}
                                                      name={`shipping${prod[0]._id}`}
                                                      onChange={() => {
                                                        shippingSelect(
                                                          prod,
                                                          ship.provider,
                                                          shipPrice.id,
                                                          shipPrice.shipment,
                                                          Number(
                                                            shipPrice.amount
                                                          )
                                                        );
                                                        shippingChange();
                                                      }}
                                                    />
                                                    <img
                                                      src={ship.providerImage}
                                                      style={{
                                                        width: "25px",
                                                        marginRight: "5px",
                                                        display: "inline-block",
                                                      }}
                                                      alt="logo"
                                                    />
                                                    <label
                                                      htmlFor={shipPrice.id}
                                                      className="bold-text h6"
                                                    >
                                                      {ship.provider} - $
                                                      {shipPrice.amount}
                                                    </label>
                                                    <span
                                                      className="h6"
                                                      style={{
                                                        marginLeft: "1rem",
                                                      }}
                                                    >
                                                      Estimated Days:{" "}
                                                      {shipPrice.days}
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      marginLeft: "22px",
                                                      marginBottom: "1.5rem",
                                                    }}
                                                  >
                                                    <div className="small-text">
                                                      Note: {shipPrice.note}
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        }
                                      )}
                                    </div>
                                  </>
                                );
                              })}
                          </div>

                          {/* KK BEGINNING */}
                          <div
                            className="order-accordian-tab"
                            style={{
                              background: "var(--primary-color)",
                              color: "var(--white-color)",
                              display: "none"
                            }}
                            onClick={() => {
                              toggleCollapse(0);
                            }}
                          >
                            {collapsed[0] ? (
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FaChevronRight />
                              </span>
                            ) : (
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {" "}
                                <FaChevronDown />
                              </span>
                            )}
                            <div>Cheapest Shipping Deals</div>{" "}
                          </div>
                          <div
                            ref={(ref) => (contentRef.current[0] = ref)}
                            style={{
                              height: collapsed[0]
                                ? "0px" : 0,
                                // : `${contentHeight[0]}px`,
                              transition: "height 0.3s ease-out",
                              overflow: "hidden",
                            }}
                          >
                            {shippingInfo[
                              shippingInfo.findIndex(
                                (x) => x.productId === prod[0]._id
                              )
                            ] !== undefined &&
                              shippingInfo[
                                shippingInfo.findIndex(
                                  (x) => x.productId === prod[0]._id
                                )
                              ].shipDetails.map((ship, index) => {
                                return (
                                  <>
                                    <div style={{display: "none"}}>
                                      {ship.shippingOption.map(
                                        (shipPrice, key) => {
                                          if (key === 0) {
                                            return (
                                              <>
                                                <div className="option">
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <input
                                                      id={shipPrice.id}
                                                      type="radio"
                                                      value={ship.provider}
                                                      style={{
                                                        marginRight: "5px",
                                                        display: "inline-block",
                                                      }}
                                                      name={`shipping${prod[0]._id}`}
                                                      onChange={() => {
                                                        shippingSelect(
                                                          prod,
                                                          ship.provider,
                                                          shipPrice.id,
                                                          shipPrice.shipment,
                                                          Number(
                                                            shipPrice.amount
                                                          )
                                                        );
                                                        shippingChange();
                                                      }}
                                                    />
                                                    <img
                                                      src={ship.providerImage}
                                                      style={{
                                                        width: "25px",
                                                        marginRight: "5px",
                                                        display: "inline-block",
                                                      }}
                                                      alt="logo"
                                                    />
                                                    <label
                                                      htmlFor={shipPrice.id}
                                                      className="bold-text h6"
                                                    >
                                                      {ship.provider} - $
                                                      {shipPrice.amount}
                                                    </label>
                                                    <span
                                                      className="h6"
                                                      style={{
                                                        marginLeft: "1rem",
                                                      }}
                                                    >
                                                      Estimated Days:{" "}
                                                      {shipPrice.days}
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      marginLeft: "22px",
                                                      marginBottom: "1.5rem",
                                                    }}
                                                  >
                                                    <div className="small-text">
                                                      Note: {shipPrice.note}
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        }
                                      )}
                                    </div>
                                  </>
                                );
                              })}
                          </div>

                          {/* KK END */}
                          {shippingInfo[
                            shippingInfo.findIndex(
                              (x) => x.productId === prod[0]._id
                            )
                          ] !== undefined &&
                            shippingInfo[
                              shippingInfo.findIndex(
                                (x) => x.productId === prod[0]._id
                              )
                            ].shipDetails.map((ship, index) => {                              
                              return (
                                <>
                                  <div
                                    className="order-accordian-tab"
                                    style={{
                                      background: "var(--primary-color)",
                                      color: "var(--white-color)",
                                      display: "none"
                                    }}
                                    onClick={() => {
                                      toggleCollapse(index + 1);
                                    }}
                                  >
                                    {collapsed[index + 1] ? (
                                      <span
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <FaChevronRight />
                                      </span>
                                    ) : (
                                      <span
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {" "}
                                        <FaChevronDown />
                                      </span>
                                    )}
                                    <div>Shipping Via {ship.provider}</div>{" "}
                                    <img
                                      src={ship.providerImage}
                                      style={{
                                        width: "25px",
                                        marginRight: "5px",
                                        display: "inline-block",
                                      }}
                                      alt="logo"
                                    />
                                  </div>
                                  <div
                                    
                                    ref={(ref) =>
                                      (contentRef.current[index + 1] = ref)
                                    }
                                    style={{
                                      height: collapsed[index + 1]
                                        ? "0px" : 0,
                                        // : `${contentHeight[index + 1]}px`,
                                      transition: "height 0.3s ease-out",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {ship.shippingOption.map(
                                      (shipPrice, key) => {
                                        return (
                                          <div  style={{display: "none", height:0}}>
                                         
                                            <div className="option">
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <input
                                                  id={shipPrice.id}
                                                  type="radio"
                                                  value={ship.provider}
                                                  style={{
                                                    marginRight: "5px",
                                                    display: "inline-block",
                                                  }}
                                                  name={`shipping${prod[0]._id}`}
                                                  onChange={() => {
                                                    shippingSelect(
                                                      prod,
                                                      ship.provider,
                                                      shipPrice.id,
                                                      shipPrice.shipment,
                                                      Number(shipPrice.amount)
                                                    );
                                                    shippingChange();
                                                  }}
                                                />
                                                <img
                                                  src={ship.providerImage}
                                                  style={{
                                                    width: "25px",
                                                    marginRight: "5px",
                                                    display: "inline-block",
                                                  }}
                                                  alt="logo"
                                                />
                                                <label
                                                  htmlFor={shipPrice.id}
                                                  className="bold-text h6"
                                                >
                                                  {ship.provider} - $
                                                  {shipPrice.amount}
                                                </label>
                                                <span
                                                  className="h6"
                                                  style={{
                                                    marginLeft: "1rem",
                                                  }}
                                                >
                                                  Estimated Days:{" "}
                                                  {shipPrice.days}
                                                </span>
                                              </div>
                                              <div
                                                style={{
                                                  marginLeft: "22px",
                                                  marginBottom: "1.5rem",
                                                }}
                                              >
                                                <div className="small-text">
                                                  Note: {shipPrice.note}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </>
                              );
                            
                            })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Order;
