import React, { useEffect, useRef, useState } from "react";
import "./Checkout.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { Formik, useFormik, useFormikContext } from "formik";
import { getuserInfo } from "../components/StateSlices/getuserSlice";
import Spinner from "../components/Spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";
import {
  getallProducts,
  reset,
} from "../components/StateSlices/getproductsSlice";
import * as Yup from "yup";
import {
  checkout,
  resetCheckout,
} from "../components/StateSlices/checkoutSlice";
import {
  deleteCheckoutItem,
  resetCheckoutDelete,
} from "../components/StateSlices/deleteCheckoutItemSlice";
import {
  getShippingPrice,
  shippingReset,
  getSalesTax,
  taxReset,
} from "../components/StateSlices/getShippingSlice";
import {
  nonUserCheckout,
  resetNCheckout,
} from "../components/StateSlices/nonCheckoutSlice";
import TagManager from "react-gtm-module";

import "react-toastify/dist/ReactToastify.css";
import Order from "../components/Order/Order";
import Billing from "../components/Billing/Billing";
import addToCart from "../assets/images/add_to_cart.svg";
import Select from "react-select";

export const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

export const ScrollToFieldError = ({
  scrollBehavior = { behavior: "smooth", block: "center" },
}) => {
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    const element = document.querySelector(
      `input[name='${fieldErrorNames[0]}']`
    );
    if (!element) return;

    // Scroll to first known error into view
    element.scrollIntoView(scrollBehavior);

    // Formik doesn't (yet) provide a callback for a client-failed submission,
    // thus why this is implemented through a hook that listens to changes on
    // the submit count.
  }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

const Checkout = ({ setCart }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo, error, profile } = useSelector((state) => state.user);
  const [spinner, setSpinner] = useState(false);
  const { deleteItemStatus, deleteItemInfo, deleteItemError } = useSelector(
    (state) => state.checkoutdelete
  );
  const { checkoutStatus, message, checkoutHistory, cherror } = useSelector(
    (state) => state.checkout
  );

  const { nmessage, checkoutNHistory, ncherror } = useSelector(
    (state) => state.nonusercheckout
  );

  const { shippingStatus, shippingInfo, shippingError, salesTax } = useSelector(
    (state) => state.shippingprice
  );
  const [shippingData, setShippingData] = useState([]);
  const { status, products, userList } = useSelector((state) => state.product);
  const [shippingErrorPlace, setShippingErrorPlace] = useState();
  const [orderedData, setOrderedData] = useState(null);
  const [price, setPrice] = useState({ hanger: 0 });
  const [outOfStock, setOutOfStock] = useState(false);
  const [cartOutOfStock, setCartOutOfStock] = useState([]);
  const [userHangerCurrent, setUserHangerCurrent] = useState(
    userInfo &&
      userInfo.userDetailsId &&
      userInfo.userDetailsId.membership != "trial"
      ? userInfo.userHanger
      : 0
  );
  const formRef = useRef();
  const [id, setId] = useState("");
  const [productInfo, setProductInfo] = useState([]);
  const [shortHanger, setShortHanger] = useState(0);
  const [totalHanger, setTotalHanger] = useState(0);
  const [userSelected, setUserSelected] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [userRole, setUserRole] = useState(null);
  const [addressDone, setAddressDone] = useState(false);

  // const usersList = [
  //   { label: "User1 - user1@gmail.com", value: "user1ID" },
  //   { label: "User2 - user2@gmail.com", value: "user2ID" },
  //   { label: "User3 - user3@gmail.com", value: "user3ID" },
  //   { label: "User4 - user4@gmail.com", value: "user4ID" },
  //   { label: "User5 - user5@gmail.com", value: "user5ID" },
  // ];

  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const toastOption2 = {
    position: "top-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#F26654" : "inherit",
      width: "100%",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#F26654",
      },
      fontSize: "14px",
      borderRadius: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      // textTransform: "capitalize",
      // marginTop: "3px",
      color: state.isSelected ? "#fff" : "#000",
      backgroundColor: state.isSelected ? "#F26654" : "#fff",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: state.isSelected
          ? "#F26654"
          : "rgba(242, 102, 84, 0.5)",
        color: "#fff",
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 31,
    }),
    // placeholder:(provided,state) => ({
    //   ...provided,
    //   textTransform: "capitalize",
    // }),
    // singleValue: (provided, state) => ({
    //   ...provided,
    //   textTransform: "capitalize",
    // }),
  };

  useEffect(() => {
    dispatch(reset());
    dispatch(shippingReset());
    // setSpinner(true)
    if (userInfo) {
      if (userRole == null) {
        setUserRole(userInfo.role);
      }
      let cart = JSON.parse(sessionStorage.getItem("closetcart")) || [];
      if (cart.length > 0) {
        dispatch(
          getallProducts(`productsId=${cart.join(",")}&userId=${userInfo._id}`)
        );
      } else {
        dispatch(
          getallProducts(
            `userProductsId=${userInfo.userProductsId._id}&userId=${userInfo._id}`
          )
        );
      }
    } else {
      let token = localStorage.getItem("clttoken");
      if (token) {
        dispatch(getuserInfo({ token: token }));
      } else {
        let cart = JSON.parse(sessionStorage.getItem("closetcart")) || [];
        console.log(cart.length);
        if (cart.length > 0) {
          dispatch(getallProducts(`productsId=${cart.join(",")}`));
        }
        //navigate("/login");
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (productInfo.length > 0) {
      setShortHanger(0);
      setTotalHanger(0);

      let hanger =
        productInfo.flat().length > 1
          ? productInfo.flat().reduce((total, product) => {
              let tt = total.productHanger ? total.productHanger : total;
              return tt + product.productHanger;
            })
          : productInfo.flat()[0].productHanger;
      console.log(hanger);
      setTotalHanger(hanger);
      let price = 0;
      let shipping = {};
      let userHanger =
        userInfo &&
        userInfo.userDetailsId &&
        userInfo.userDetailsId.membership != "trial"
          ? userInfo.userHanger
          : 0;
      productInfo.map((prod) => {
        prod.map((product) => {
          console.log(userHanger);

          if (!product.productStock > 0) {
            setOutOfStock(true);
            console.log("PPP", product._id);
            if (!cartOutOfStock.includes(product._id))
              setCartOutOfStock((prev) => [
                ...prev,
                { id: product._id, name: product.productName },
              ]);
          }

          if (userHanger < product.productHanger) {
            price +=
              userInfo &&
              userInfo.userDetailsId &&
              userInfo.userDetailsId.membership != "trial"
                ? 5 * (product.productHanger - userHanger)
                : 7 * (product.productHanger - userHanger);
            let cr = userHanger;

            setShortHanger((prevHanger) => {
              return prevHanger + (product.productHanger - cr);
            });
          }
          userHanger =
            userHanger - product.productHanger < 0
              ? 0
              : userHanger - product.productHanger;

          shipping[product._id] = [0, "free"];
        });
      });
      setPrice({ hanger, price, shipping, salesTax: 0 });
      if (userInfo) {
        handleCalculate();
      } else {
        setShippingData(null);
      }
    }
  }, [productInfo]);

  useEffect(() => {
    if (userList.length > 0) {
      let users = userList.map((user) => ({
        label: `${
          (user.userFName ? user.userFName : "") +
          " " +
          (user.userLName ? user.userLName : "")
        } ${user.userFName && user.userLName ? "-" : ""} ${user.userEmail}`,
        value: user.userMasterId,
      }));
      setUsersList(users);
    }
    if (products.length > 0) {
      console.log(products);
      // group products based on closetId
      let closetId = products.map((product) => product.closetId);
      let closetIdGroup = closetId.reduce((acc, el, i) => {
        acc[el] = (acc[el] || []).concat(products[i]);
        return acc;
      }, {});
      console.log(closetIdGroup);
      let closetIdGroupArray = Object.values(closetIdGroup);
      console.log(closetIdGroupArray);
      setProductInfo(closetIdGroupArray);
      dispatch(reset());
    }

    //setSpinner(false)

    console.log(status);
  }, [products]);

  useEffect(() => {
    if (cartOutOfStock.length > 0) {
      cartOutOfStock.map((prod) => {
        console.log(prod);
        // toast.error(`${prod.name?prod.name:"Item"} has been purchased by someone else or is no longer available. Please remove the item and continue`,toastOption2)

        let err = `<strong>${
          prod.name ? prod.name : "Item"
        }</strong> has been purchased by someone else or is no longer available. Please remove the item and continue`;

        toast.error(
          ({ closeToast }) => <div dangerouslySetInnerHTML={{ __html: err }} />,
          toastOption2
        );
      });
    }
  }, [cartOutOfStock]);

  useEffect(() => {
    if (status == "loading" || shippingStatus == "loading") {
      console.log(status, shippingStatus);
      setSpinner(true);
    } else {
      setSpinner(false);
    }
  }, [status, shippingStatus]);

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      phone: "",
    },
    validationSchema: Yup.object({
      phone: Yup.number()
        .typeError("Enter a valid zipcode")
        .required("Please enter your phone number"),
      address: Yup.string().required("Please enter your street address"),
      city: Yup.string().required("Please enter your city"),
      state: Yup.string().required("Please select your state"),
      zipCode: Yup.number()
        .typeError("Enter a valid zipcode")
        .max(99950, "Please enter valid zipcode")
        .required("Please enter your zipcode"),
      name: Yup.string().required("Please enter Name"),
    }),
    onSubmit: (values, { setSubmitting, setValues }) => {
      console.log(values);
      // let token = localStorage.getItem("clttoken")
      //       if(token){
      //           setSpinner(true);
      //           //postDetails(values,setSubmitting, setValues,token);

      //       }else{
      //           navigate("/")
      //       }

      return false;
    },
  });

  let lastDeletedId;

  useEffect(() => {
    console.log(shippingInfo);
    if (shippingInfo) {
      let tmp = shippingInfo.map((ship) => {
        let z = ship.shipDetails.map((shipProvider) => {
          let k = [...shipProvider.shippingOption].sort(
            (a, b) => Number(a.amount) - Number(b.amount)
          );
          console.log(k);
          return {
            provider: shipProvider.provider,
            providerImage: shipProvider.providerImage,
            shippingOption: k,
          };
        });
        return {
          productId: ship.productId,
          localPickup: ship.localPickup,
          shipDetails: z,
        };
      });

      setShippingData(tmp);
      setSpinner(false);
    }
    console.log(shippingInfo);

    if (shippingError) {
      setShippingErrorPlace("Place not found");
    }
    // return () => {
    //   dispatch(shippingReset())
    // }
  }, [shippingError, shippingInfo]);

  useEffect(() => {
    console.log("SLAEX", salesTax);
    if (salesTax) {
      const taxBreakdown = salesTax.tax_breakdown;
      const totalTaxRatePercentage = taxBreakdown.reduce((total, breakdown) => {
        const percentage = parseFloat(
          breakdown.tax_rate_details.percentage_decimal
        );
        return total + percentage;
      }, 0);
      let shippingTax = 0;
      if (salesTax.shipping_cost) {
        shippingTax = salesTax.shipping_cost.amount_tax / 100;
      }
      let tax = {
        taxId: salesTax.id,
        amount: salesTax.tax_amount_exclusive / 100,
        percent: totalTaxRatePercentage,
        shippingTax,
      };
      setPrice((prevState) => {
        return {
          ...prevState,
          salesTax: tax,
        };
      });
      // dispatch(taxReset())
    } else {
      let tax = {
        taxId: 0,
        amount: 0,
        percent: 0,
      };
      setPrice((prevState) => {
        return {
          ...prevState,
          salesTax: tax,
        };
      });
    }
  }, [salesTax]);

  const removekey = (id) => {
    lastDeletedId = id;
    swal({
      title: "Are you sure?",
      text: "This Item will be Removed from Your Cart",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log(`Please Delete it. ${id}`);
        let token = localStorage.getItem("clttoken");
        setSpinner(true);
        let cart = JSON.parse(sessionStorage.getItem("closetcart")) || [];
        let index = cart.indexOf(id);
        if (index > -1) {
          cart.splice(index, 1);
        }
        setCart(cart);
        setUserSelected(null);
        sessionStorage.setItem("closetcart", JSON.stringify(cart));
        dispatch(deleteCheckoutItem({ token, id }));
        setCartOutOfStock([]);
        setOutOfStock(false);
        if (cart.length === 0) setProductInfo([]);
      }
    });
  };

  const removeSessionKey = (id) => {
    swal({
      title: "Are you sure?",
      text: "This Item will be Removed from Your Cart",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let cart = JSON.parse(sessionStorage.getItem("closetcart")) || [];
        let index = cart.indexOf(id);
        if (index > -1) {
          cart.splice(index, 1);
        }
        setCart(cart);
        sessionStorage.setItem("closetcart", JSON.stringify(cart));
        if (cart.length > 0) {
          dispatch(getallProducts(`productsId=${cart.join(",")}`));
          setCartOutOfStock([]);
          setOutOfStock(false);
        } else setProductInfo([]);
      }
    });
  };

  if (message) {
    dispatch(resetCheckout());
    toast.success("Successfully Purchased Product", toastOption);
    sessionStorage.setItem("closetcart", JSON.stringify([]));
    setCart([]);
    console.log(checkoutHistory);
    let token = localStorage.getItem("clttoken");
    dispatch(getuserInfo({ token }));
    let originalPrice = checkoutHistory.products.reduce((acc, product) => {
      return acc + parseFloat(product.productId.productPrice);
    }, 0);
    let uhanger = Math.abs(price.hanger - shortHanger);
    let items = checkoutHistory.products.map((product, index) => {
      let prod = product.productId;
      return {
        item_id: prod._id,
        item_name: prod.productName,
        affiliation: null,
        coupon: null,
        currency: "USD",
        discount: 0,
        index,
        item_brand: prod.productStats.brand,
        item_category: prod.productStats.category[0],
        item_category2: prod.productStats.category[1]
          ? prod.productStats.category[1]
          : "",
        item_category3: prod.productStats.category[2]
          ? prod.productStats.category[2]
          : "",
        item_category4: prod.productStats.category[3]
          ? prod.productStats.category[3]
          : "",
        item_category5: prod.productStats.category[4]
          ? prod.productStats.category[4]
          : "",
        item_list_id: null,
        item_list_name: null,
        item_variant: null,
        item_list_location: null,
        price: Number(
          Number(
            product.hangerPurchased *
              (price.price / checkoutHistory.hangerPurchased)
          ).toFixed(0)
        ),
        quantity: 1,
      };
    });
    let done = [];
    let shipPrice = Object.values(price.shipping).reduce(
      (accumulator, value) => {
        if (done.includes(value[2])) {
          return accumulator;
        } else {
          done.push(value[2]);
          return accumulator + value[0];
        }
      },
      0
    );
    let tax = price.salesTax.amount ? price.salesTax.amount : 0;
    console.log(checkoutHistory);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "Checkout Conversion",
      checkoutId: checkoutHistory._id,
      products: checkoutHistory.products,
      orderId: checkoutHistory.orderId,
      hangersUsed: totalHanger,
      hangersPurchased: Number(checkoutHistory.hangerPurchased),
      creditPrice: Number(
        Number(
          checkoutHistory.hangerPurchased *
            (price.price / checkoutHistory.hangerPurchased)
        ).toFixed(0)
      ),
      originalPrice: originalPrice,
      items,
      affiliation: null,
      coupon: null,
      shippingPrice: shipPrice,
      tax: tax,
      currency: "USD",
      price: Number(
        Number(
          checkoutHistory.hangerPurchased * checkoutHistory.hangerPurchased != 0
            ? price.price / checkoutHistory.hangerPurchased
            : 0
        ).toFixed(0)
      ),
    });
    TagManager.dataLayer({ dataLayer: window.dataLayer });
    setTimeout(() => {
      navigate("/order-confirmation", {
        state: { ...checkoutHistory, price, shortHanger },
      });
    }, 5000);
  }

  if (nmessage) {
    dispatch(resetNCheckout());
    toast.success("Successfully Purchased Product", toastOption);
    sessionStorage.setItem("closetcart", JSON.stringify([]));
    setCart([]);
    let originalPrice = checkoutNHistory.products.reduce((acc, product) => {
      return acc + parseFloat(product.productId.productPrice);
    }, 0);
    let uhanger = Math.abs(price.hanger - shortHanger);
    let items = checkoutNHistory.products.map((product, index) => {
      let prod = product.productId;
      return {
        item_id: prod._id,
        item_name: prod.productName,
        affiliation: null,
        coupon: null,
        currency: "USD",
        discount: 0,
        index,
        item_brand: prod.productStats.brand,
        item_category: prod.productStats.category[0],
        item_category2: prod.productStats.category[1]
          ? prod.productStats.category[1]
          : "",
        item_category3: prod.productStats.category[2]
          ? prod.productStats.category[2]
          : "",
        item_category4: prod.productStats.category[3]
          ? prod.productStats.category[3]
          : "",
        item_category5: prod.productStats.category[4]
          ? prod.productStats.category[4]
          : "",
        item_list_id: null,
        item_list_name: null,
        item_variant: null,
        item_list_location: null,
        price: Number(product.hangerPurchased * 7),
        quantity: 1,
      };
    });
    let done = [];
    let shipPrice = Object.values(price.shipping).reduce(
      (accumulator, value) => {
        if (done.includes(value[2])) {
          return accumulator;
        } else {
          done.push(value[2]);
          return accumulator + value[0];
        }
      },
      0
    );
    let tax = price.salesTax.amount ? price.salesTax.amount : 0;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "Checkout Conversion",
      checkoutId: checkoutNHistory._id,
      products: checkoutNHistory.products,
      hangersUsed: totalHanger,
      orderId: checkoutNHistory.orderId,
      hangersPurchased: totalHanger,
      creditPrice: Number(checkoutNHistory.hangerPurchased * 7),
      originalPrice: originalPrice,
      items,
      affiliation: null,
      coupon: null,
      shippingPrice: shipPrice,
      tax: tax,
      currency: "USD",
    });
    setTimeout(() => {
      navigate("/order-confirmation", {
        state: { ...checkoutNHistory, price, shortHanger },
      });
    }, 5000);
  }

  if (cherror) {
    if (cherror === "Some Products are out of stock") {
      let cart = JSON.parse(sessionStorage.getItem("closetcart")) || [];
      if (cart.length > 0) {
        dispatch(
          getallProducts(`productsId=${cart.join(",")}&userId=${userInfo._id}`)
        );
      } else {
        dispatch(
          getallProducts(
            `userProductsId=${userInfo.userProductsId._id}&userId=${userInfo._id}`
          )
        );
      }
    }
    toast.error(cherror, toastOption);
    dispatch(resetCheckout());
    setSpinner(false);
  }

  if (ncherror) {
    if (ncherror === "Some Products are out of stock") {
      let cart = JSON.parse(sessionStorage.getItem("closetcart")) || [];
      console.log(cart.length);
      if (cart.length > 0) {
        dispatch(getallProducts(`productsId=${cart.join(",")}`));
      }
    }
    toast.error(ncherror, toastOption);
    dispatch(resetNCheckout());
    setSpinner(false);
  }

  if (deleteItemInfo) {
    console.log(deleteItemInfo);
    dispatch(resetCheckoutDelete());
    setShortHanger((prevHanger) => prevHanger - 1);
    toast.success("Product Successfully Removed", toastOption);
    setTimeout(() => {
      setSpinner(false);

      let token = localStorage.getItem("clttoken");
      if (token) {
        dispatch(getuserInfo({ token: token }));
      } else {
        navigate("/login", { replace: true });
      }
    }, 5000);
  }

  const handleCalculate = () => {
    let error = false;
    console.log(formRef.current);
    formRef.current.validateForm().then((errors) => {
      for (const key in errors) {
        if (errors[key]) {
          error = true;
          formRef.current.setFieldError(key, "Required");
          formRef.current.setFieldTouched(key, true);
        }
      }
      if (error) {
        const firstErrorField = Object.keys(errors)[0];
        if (firstErrorField) {
          const errorFieldElement = document.querySelector(
            `[name="${firstErrorField}"]`
          );
          if (errorFieldElement) {
            errorFieldElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
            errorFieldElement.focus();
          }
        }
      } else {
        let toAddress = formRef.current.values;
        setShippingErrorPlace("");
        dispatch(shippingReset());
        let shipping = {};
        let shippingDetails = productInfo.flat().map((product) => {
          shipping[product._id] = [0, "free"];
          console.log(product);
          let closetItem = 1;
          // also make variable closetItem field which shows how much products from one closetId
          closetItem = productInfo
            .flat()
            .filter((item) => item.closetId === product.closetId).length;

          return {
            productId: product._id,
            hanger: product.productHanger,
            closetId: product.closetId,
            closetItem,
            addressDetail: {
              name: product.name,
              street1: product.addr,
              city: product.city,
              state: product.state,
              zip: product.zip,
              phone: product.phone,
            },
          };
        });
        setPrice((prevPrice) => {
          return {
            ...prevPrice,
            shipping,
          };
        });
        let token = localStorage.getItem("clttoken");
        let payload = { toAddress, shippingDetails };
        dispatch(getShippingPrice({ shippingData: payload, token }));
        setSpinner(true);
        setAddressDone(true);
      }
    });
  };

  const changeUser = (val) => {
    let token = localStorage.getItem("clttoken");
    dispatch(getuserInfo({ token: token, id: val.value }));
  };

  const shippingChange = () => {};

  return (
    <div className="page-container" style={{ position: "relative" }}>
      {spinner ? <Spinner /> : ""}

      {/* <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}

      <div className="checkout">
        {!spinner && productInfo.length === 0 ? (
          <div className="no_item" style={{ marginTop: 0, paddingTop: "8rem" }}>
            <h1
              className="no_checkout"
              style={{ marginBottom: "1rem", marginTop: 0 }}
            >
              No Products in Shopping Bag
            </h1>
            <img src={addToCart} alt="add-to-cart-icon" />
            <br />
            <Link to={"/shop"}>
              <button
                className="btn"
                style={{ marginTop: "2rem", marginBottom: "8rem" }}
              >
                Shop Items
              </button>
            </Link>
          </div>
        ) : (
          <>
            {/* Ruh roh!  You don’t have enough hanger credits for the items in your shopping bag.
            Click here to add more items to earn more credits
            Remove items from your bag 
            Buy hanger credits at the member discount price of $5 / hanger 
            Ruh roh!  You aren’t a member of Closest Closet!  (Login here if you already are)
            Click here to become a member and get these items for free!  
            Don’t worry, you can still buy hanger credits at for $7 / hanger if you don’t want to become a member*/}
            {shortHanger > 0 &&
            userInfo &&
            userInfo.userDetailsId &&
            userInfo.userDetailsId.membership != "trial" ? (
              <div className="hanger-short-warning">
                <div className="hanger-short-warning-text-title">
                  Ruh roh! You don’t have enough hanger credits for the items in
                  your shopping bag.
                </div>
                <div className="hanger-short-warning-text">
                  <div>Options:</div>
                  <div>
                    <ul>
                      <li>
                        <Link
                          to={"/add-items"}
                          style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                        >
                          Click here
                        </Link>{" "}
                        to add more items to earn more credits
                      </li>
                      <li>Remove items from your bag</li>
                      <li>
                        Buy hanger credits at the member discount price of $5 /
                        hanger
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : shortHanger > 0 ? (
              <div className="hanger-short-warning">
                <div className="hanger-short-warning-text-title">
                  Ruh roh! You aren’t a member of Closest Closet!{" "}
                  {userInfo ? (
                    <>
                      <Link
                        to={"/user/membership"}
                        style={{
                          fontWeight: "bold",
                          textDecoration: "underline",
                        }}
                      >
                        Click Here
                      </Link>{" "}
                      to upgrade your membership
                    </>
                  ) : (
                    <>
                      <Link
                        to={"/login"}
                        style={{
                          fontWeight: "bold",
                          textDecoration: "underline",
                        }}
                      >
                        Login here
                      </Link>{" "}
                      if you already are a member
                    </>
                  )}
                </div>
                <div className="hanger-short-warning-text">
                  <div>Options:</div>
                  <div>
                    <ul>
                      <li>Become a member and get these items for free!</li>
                      <li>
                        Don’t worry, you can still buy hanger credits at for $7
                        / hanger if you don’t want to become a member
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="title">Shopping Bag</div>
            {userInfo && userRole === "admin" && (
              <div className="select_container" style={{ fontFamily: "LATO" }}>
                <div style={{ marginBottom: ".5rem" }}>
                  <label
                    htmlFor=""
                    className="item_title h3"
                    style={{ marginBottom: ".5rem" }}
                  >
                    User
                  </label>
                </div>
                <Select
                  name="userSelected"
                  options={usersList.sort((a, b) =>
                    a.label.localeCompare(b.label)
                  )}
                  onChange={(selectedOption) => {
                    setUserSelected(selectedOption);
                    changeUser(selectedOption);
                  }}
                  value={userSelected}
                  styles={customStyles}
                />
              </div>
            )}

            <div className="container">
              <div className="checkout-left">
                <Order
                  shippingErrorPlace={setShippingErrorPlace}
                  shippingInfo={shippingData}
                  products={productInfo}
                  setPrice={setPrice}
                  removekey={removekey}
                  userInfo={userInfo}
                  removeSessionKey={removeSessionKey}
                  shippingChange={shippingChange}
                  getSalesTax={getSalesTax}
                  dispatch={dispatch}
                  fullPrice={price}
                  formRef={formRef}
                  taxReset={taxReset}
                  cartOutOfStock={cartOutOfStock}
                />
              </div>
              <div className="checkout-right">
                <Formik
                  initialValues={{
                    email: userInfo ? userInfo.userEmail : "",
                    name: userInfo
                      ? (userInfo.userDetailsId.userFName
                          ? userInfo.userDetailsId.userFName
                          : "") +
                        " " +
                        (userInfo.userDetailsId.userLName
                          ? userInfo.userDetailsId.userLName
                          : "")
                      : "",
                    address: userInfo ? userInfo.userDetailsId.userAddress : "",
                    city: userInfo ? userInfo.userDetailsId.userCity : "",
                    state: userInfo ? userInfo.userDetailsId.userState : "",
                    zipCode: userInfo ? userInfo.userDetailsId.userZipCode : "",
                    phone: userInfo ? userInfo.userDetailsId.userPhone : "",
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email("Invalid email address")
                      .required("Please enter email address"),
                    name: Yup.string().required("Please enter Name"),
                    address: Yup.string().required(
                      "Please enter your street address"
                    ),
                    city: Yup.string().required("Please enter your city"),
                    state: Yup.string().required("Please select your state"),
                    zipCode: Yup.number()
                      .typeError("Enter a valid zipcode")
                      .max(99950, "Please enter valid zipcode")
                      .required("Please enter your zipcode"),
                    phone: Yup.number()
                      .typeError("Enter a valid phone number")
                      .required("Please enter your phone number"),
                  })}
                  onSubmit={(values, actions) => {
                    
                    const hasHighLocalPickup = shippingInfo.some(
                      (info) => info.localPickup > 25
                    );

                    if (hasHighLocalPickup) {
                      swal({
                        title: `High Local Pickup Cost`,
                        text: `Local pickup cost exceeds $25. Do you want to proceed?`,
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                      }).then(async (willReactive) => {
                        try {
                          // console.log(willReactive);
                          if (willReactive) {
                            setSpinner(true);
                            let payload = { ...values };
                            payload.token = id;
                            payload.cId = userInfo
                              ? userInfo.userDetailsId.paymentId
                              : "";
                            // productsInfo get only products Id grouped by closetId
                            payload.productsInfo = productInfo;
                            let cart =
                              JSON.parse(
                                sessionStorage.getItem("closetcart")
                              ) || [];

                            payload.price = price;
                            payload.externalUserId = userSelected
                              ? userSelected.value
                              : "";

                            payload.products = userSelected
                              ? JSON.parse(sessionStorage.getItem("closetcart"))
                              : userInfo
                              ? userInfo.userProductsId.cartId.length > 0
                                ? userInfo.userProductsId.cartId
                                : cart
                              : JSON.parse(
                                  sessionStorage.getItem("closetcart")
                                );
                            payload.totalHanger = totalHanger;
                            setOrderedData(payload);
                            console.log(payload);
                            let token = localStorage.getItem("clttoken") || "";

                            if (userInfo)
                              dispatch(
                                checkout({ checkoutData: payload, token })
                              );
                            else
                              dispatch(
                                nonUserCheckout({
                                  checkoutData: payload,
                                  token: "",
                                })
                              );
                            return false;
                          } else {
                            return;
                          }
                        } catch (error) {
                          console.log(error);
                        }
                      });
                    } else {
                      setSpinner(true);
                      let payload = { ...values };
                      payload.token = id;
                      payload.cId = userInfo
                        ? userInfo.userDetailsId.paymentId
                        : "";
                      // productsInfo get only products Id grouped by closetId
                      payload.productsInfo = productInfo;
                      let cart =
                        JSON.parse(sessionStorage.getItem("closetcart")) || [];

                      payload.price = price;
                      payload.externalUserId = userSelected
                        ? userSelected.value
                        : "";

                      payload.products = userSelected
                        ? JSON.parse(sessionStorage.getItem("closetcart"))
                        : userInfo
                        ? userInfo.userProductsId.cartId.length > 0
                          ? userInfo.userProductsId.cartId
                          : cart
                        : JSON.parse(sessionStorage.getItem("closetcart"));
                      payload.totalHanger = totalHanger;
                      setOrderedData(payload);
                      console.log(payload);
                      let token = localStorage.getItem("clttoken") || "";

                      if (userInfo)
                        dispatch(checkout({ checkoutData: payload, token }));
                      else
                        dispatch(
                          nonUserCheckout({ checkoutData: payload, token: "" })
                        );
                      return false;
                    }
                    // setSpinner(true);
                    // let payload = { ...values };
                    // payload.token = id;
                    // payload.cId = userInfo
                    //   ? userInfo.userDetailsId.paymentId
                    //   : "";
                    // // productsInfo get only products Id grouped by closetId
                    // payload.productsInfo = productInfo;
                    // let cart =
                    //   JSON.parse(sessionStorage.getItem("closetcart")) || [];

                    // payload.price = price;
                    // payload.externalUserId = userSelected
                    //   ? userSelected.value
                    //   : "";

                    // payload.products = userSelected?JSON.parse(sessionStorage.getItem("closetcart")):userInfo
                    //   ? userInfo.userProductsId.cartId.length > 0
                    //     ? userInfo.userProductsId.cartId
                    //     : cart
                    //   : JSON.parse(sessionStorage.getItem("closetcart"));
                    // payload.totalHanger = totalHanger;
                    // setOrderedData(payload);
                    // console.log(payload);
                    // let token = localStorage.getItem("clttoken") || "";

                    // if (userInfo)
                    //   dispatch(checkout({ checkoutData: payload, token }));
                    // else
                    //   dispatch(
                    //     nonUserCheckout({ checkoutData: payload, token: "" })
                    //   );
                    // return false;
                  }}
                  innerRef={formRef}
                  render={(props) => (
                    <Billing
                      userInfo={userInfo}
                      setId={setId}
                      formik={formik}
                      setPrice={setPrice}
                      price={price}
                      shortHanger={shortHanger}
                      handleCalculate={handleCalculate}
                      spinner={spinner}
                      setSpinner={setSpinner}
                      ScrollToFieldError={ScrollToFieldError}
                      addressDone={addressDone}
                      outOfStock={outOfStock}
                      {...props}
                    />
                  )}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Checkout;
