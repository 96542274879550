import React, { useState } from "react";
import "./Alert.css";
import { BsInfoCircle, BsTwitterX } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { referLink, resetItem } from "../StateSlices/referLinkSlice";
import { MdContentCopy } from "react-icons/md";
import { FaFacebookF, FaInstagram, FaTiktok, FaTwitter, FaWhatsapp } from "react-icons/fa";

const Alert = ({ msg }) => {
  const navigate = useNavigate();
  // var close = document.getElementsByClassName("alertCloseBtn");
  var close = document.getElementsByClassName("alert");
  var i;

  for (i = 0; i < close.length; i++) {
    close[i].onclick = function () {
      setIsReferModalActive(false);
      var div = this.parentElement;
      div.style.opacity = "0";
      setTimeout(function () {
        div.style.display = "none";
      }, 600);
    };
  }

  const dispatch = useDispatch();
  const { status, userInfo, error, profile } = useSelector(
    (state) => state.user
  );
  const { referStatus, referInfo, referError } = useSelector(
    (state) => state.referLink
  );

  const [isReferModalActive, setIsReferModalActive] = useState(false);
  const [refUrl, setRefUrl] = useState("");
  // console.log("header2", userInfo, profile);
  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  if (referInfo) {
    let referId = referInfo;
    dispatch(resetItem());
    setRefUrl(
      "https://closestcloset.com/signup?referral=" + encodeURIComponent(referId)
    );
    setIsReferModalActive((prev) => !prev);
  }

  const getUrl = () => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(referLink({ token: token }));
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      {/* <ToastContainer
        position="bottom-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <Modal
        aria-modal="true"
        role="dialog"
        ariaHideApp={false}
        isOpen={isReferModalActive}
        onRequestClose={() => setIsReferModalActive(false)}
        style={{
          overlay: {
            background: "rgba(0,0,0,0.5)",
            zIndex: 70,
          },
          content: {
            maxWidth: "600px",
            maxHeight: "fit-content",
            margin: "auto",
            position: "absolute",
          },
        }}
        contentLabel="Example Modal"
      >
        <div
          className="close_container"
          style={{ display: "flex", justifyContent: "end" }}
        >
          {/* <button
              className="btn dark-btn"
              style={{ padding: "4px 9.5px", borderRadius: "2px" }}
              onClick={() => this.setState({ modalIsOpen: false })}
            >
              X
            </button> */}
        </div>

        <div className="terms-modal-con">
          <div
            className="terms-modal-wrapper"
            style={{ marginTop: 0, padding: "1.5rem 0", paddingTop: ".5rem" }}
          >
            <h3 className="primary-text" style={{ marginBottom: ".5rem" }}>
              Refer to your friends
            </h3>
            <h6 className="background-text">
              Members earn 5 bonus hanger credits + $5 off their next
              subscription fee on each paid member referral!
            </h6>
            <div className="reference-section">
              <input type="text" id="reference-input" value={refUrl} disabled />
              <button
                id="copy-button"
                onClick={() => {
                  // refUrl.select();
                  // refUrl.setSelectionRange(0, 99999); // For mobile devices
                  // Copy the text inside the text field
                  navigator.clipboard.writeText(refUrl);
                  toast.success(
                    "Referral URL copied successfully!",
                    toastOption
                  );
                }}
              >
                <MdContentCopy />
              </button>
            </div>
            <div className="share-section">
              <button style={{background: "#3b5998", color: "white"}} onClick={() => {
                // ALSO WRITE SOME NICE MESSAGE
                // give it like use my referral link to get 5 bonus hanger credits + $5 off your next subscription fee. Here's the link:
                const message = "I want to shop your closet!  Join me @  closestcloset.com where I am able to post my own fashion items that I'll never wear again to earn hanger credits that I can use to shop other members' items that I actually will wear for FREE...check it out here and if you sign up for a monthly or annual membership with this link, you'll get a bonus 5 free hanger credits (6 total!)";
                console.log(refUrl)
                window.open(
                  `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    refUrl
                  )}`
                );
              }}>
                <FaFacebookF size={25}/>
                
              </button>

              {/*  make Twittter X */}
              <button style={{background: "#000", color: "white"}} onClick={() => {
                const message = "📢👋I want to shop your closet!  Join me @  closestcloset.com where I shop for FREE...check it out here and if you sign up for a monthly or annual membership with this link, you'll get a bonus 5 free hanger credits (6 total!)";
                window.open(
                  `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                    refUrl
                  )}&text=${encodeURIComponent(message)}`
                );
              }}>
                <BsTwitterX size={25}/>
              </button>
              {/*  add this also below background: linear-gradient(
    to right,
    #833ab4,#fd1d1d,#fcb045
  ); */}
              <button style={{ background: "#833ab4", background: "linear-gradient(145deg, #833ab4,#fd1d1d,#fcb045)",color: "white"}} onClick={() => {
                window.open(
                  `https://www.instagram.com/`
                );
              }}>
                <FaInstagram size={25}/>
              </button>
              <button style={{background: "#25D366", color: "white"}} onClick={() => {
                const message = "📢👋I want to shop your closet!  Join me @  closestcloset.com where I am able to post my own fashion items that I'll never wear again to earn hanger credits that I can use to shop other members' items that I actually will wear for FREE...check it out here and if you sign up for a monthly or annual membership with this link, you'll get a bonus 5 free hanger credits (6 total!)";
                window.open(
                  `https://api.whatsapp.com/send?text=${message}${encodeURIComponent(
                    refUrl
                  )}`
                );
              }}>
                <FaWhatsapp size={25}/>
              </button>
              {/* TikTok */}
              {/* <button style={{background: "#000", color: "white"}} onClick={() => {
                window.open(
                  `https://www.tiktok.com/`
                );
              }}>
                <FaTiktok size={25}/>
              </button> */}
            </div>
          </div>
        </div>
      </Modal>
      <div
        className="alert wrapper h6"
        onClick={() => getUrl()}
        style={{ cursor: "pointer" }}
        role="button"
        aria-label="Alert"
        tabIndex={0}
      >
        <div tabIndex={0}>
          <span className="dot" role="presentation" ></span>
        </div>
        <div tabIndex={0}>
          <span className="dot" role="presentation" tabIndex={0}></span>
        </div>
        <div tabIndex={0}>
          <span className="dot" role="presentation" tabIndex={0}></span>
        </div>
        <div tabIndex={0}>
          <span className="dot" role="presentation" tabIndex={0}></span>
        </div>
        <div tabIndex={0}>
          <span className="dot" role="presentation" tabIndex={0}></span>
        </div>
        <div tabIndex={0}>
          <span className="dot" role="presentation" tabIndex={0}></span>
        </div>
        <div tabIndex={0}>
          <span className="dot" role="presentation" tabIndex={0}></span>
        </div>
        <div tabIndex={0}>
          <span className="dot" role="presentation" tabIndex={0}></span>
        </div>
        <div tabIndex={0}>
          <span className="dot" role="presentation" tabIndex={0}></span>
        </div>
        <div tabIndex={0}>
          <span className="dot" role="presentation" tabIndex={0}></span>
        </div>
        <div tabIndex={0}>
          <span className="dot" role="presentation" tabIndex={0}></span>
        </div>
        {/* <span class="alertCloseBtn">&times;</span> */}
        <strong>
          {/* <BsInfoCircle size={0} style={{ lineHeight: "1.5" }} /> */}
        </strong>{" "}
        {msg}
      </div>
    </>
  );
};

export default Alert;
