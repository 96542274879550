import React, { useEffect, useState } from "react";
import Spinner from "../components/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { getLeaderboardResults } from "../components/StateSlices/getLeaderboardResultsSlice";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import "./LeaderboardNew.css";
import { FaEarthAmericas, FaRecycle } from "react-icons/fa6";
import { BsFillSendArrowUpFill, BsHandbagFill } from "react-icons/bs";
import { MdBarChart, MdSell } from "react-icons/md";
import noCloset1 from "../assets/images/qc1.jpeg";
import noCloset2 from "../assets/images/qc4.png";
import noCloset3 from "../assets/images/qc3.png";
import MyClosetBanner from "../components/MyClosetNew/MyClosetBanner";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { PiCoatHangerFill } from "react-icons/pi";

const Leaderboard = () => {
  const classes = { 1: "first", 2: "second", 3: "third" };
  const suffix = {
    1: "st",
    2: "nd",
    3: "rd",
    4: "th",
    5: "th",
    6: "th",
    7: "th",
    8: "th",
    9: "th",
    10: "th",
  };
  const badges = { 1: "🥇", 2: "🥈", 3: "🥉" };
  const noClosetIconList = { 1: noCloset1, 2: noCloset2, 3: noCloset3 };

  const [data, setData] = useState([]);
  const [bannerObj, setBanneObj] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { status, leaderboardResults, error } = useSelector(
    (state) => state.getleaderboardresults
  );
  const { userInfo } = useSelector((state) => state.user);

  useEffect(() => {
    // Simulate an API request
    dispatch(getLeaderboardResults({}));
    setLoading(true);
  }, []);

  useEffect(() => {
    if (leaderboardResults) {
      const globalItemsPosted =
        leaderboardResults.globalPostedTotals.globalTotalProductsPosted;
      const globalItemsPostedHangersEarned =
        leaderboardResults.globalPostedTotals.globalTotalHangersEarned;

      const globalItemsBought =
        leaderboardResults.globalBoughtTotals.globalTotalProductsBought;
      const globalItemsBoughtHangersRedeemed =
        leaderboardResults.globalBoughtTotals.globalTotalHangersRedeemed;

      const globalItemsSold =
        leaderboardResults.globalSoldTotals.globalCommonProductCount;
      const globalItemsSoldHangersRedeemed =
        leaderboardResults.globalSoldTotals.globalHangersRedeemed;
      setBanneObj([
        {
          id: 1,
          title:
            "Item Posted : " +
            (globalItemsPosted
              ? parseInt(globalItemsPosted).toLocaleString("en-US")
              : 0),
          titleTooltip: "Number of items posted by globally",
          content: [
            {
              icon: <FaRecycle size={"1.5em"} />,
              paragraph: "Potential Carbon Savings",
              numInfo:
                (globalItemsPosted
                  ? (parseInt(globalItemsPosted) * 25).toLocaleString("en-US")
                  : 0) + "Kg CO2",
              tooltip: "Amount of Carbon Saved globally",
            },

            {
              icon: <PiCoatHangerFill size={"1.7em"} />,
              paragraph: "Posted Item Hangers Earned",
              numInfo:
                "$" +
                (globalItemsPostedHangersEarned
                  ? parseInt(globalItemsPostedHangersEarned).toLocaleString(
                      "en-US"
                    )
                  : 0),
              tooltip: "Posted Items Hanger Values Earned globally",
            },
          ],
        },
        {
          id: 2,
          title:
            "Item Purchased : " +
            (globalItemsBought
              ? parseInt(globalItemsBought).toLocaleString("en-US")
              : 0),
          titleTooltip: "Number of Items Purchased globally",
          content: [
            {
              icon: <FaRecycle size={"1.5em"} />,
              paragraph: "Actual Carbon Savings",
              numInfo:
                (globalItemsBought
                  ? (parseInt(globalItemsBought) * 25).toLocaleString("en-US")
                  : 0) + "Kg CO2",
              tooltip: "Amount of Actual Carbon Savings",
            },

            {
              icon: <PiCoatHangerFill size={"1.7em"} />,
              paragraph: "Purchased Item Hangers Redeemed",
              numInfo:
                "$" +
                (globalItemsBoughtHangersRedeemed
                  ? parseInt(globalItemsBoughtHangersRedeemed).toLocaleString(
                      "en-US"
                    )
                  : 0),

              tooltip: "Purchased Item Hanger Values Redeemed",
            },
          ],
        },
        {
          id: 3,
          title:
            "Item Sold : " +
            (globalItemsSold
              ? parseInt(globalItemsSold).toLocaleString("en-US")
              : 0),
          titleTooltip: "Number of sold items globally",
          content: [
            {
              icon: <FaRecycle size={"1.5em"} />,
              paragraph: "Actual Carbon Savings",
              numInfo:
                (globalItemsSold
                  ? (parseInt(globalItemsSold) * 25).toLocaleString("en-US")
                  : 0) + "Kg CO2",
              tooltip:
                "Amount of actual carbon saved by you by selling globally",
            },

            {
              icon: <PiCoatHangerFill size={"1.7em"} />,
              paragraph: "Sold Item Hangers Redeemed",
              numInfo:
                "$" +
                (globalItemsSoldHangersRedeemed
                  ? parseInt(globalItemsSoldHangersRedeemed).toLocaleString(
                      "en-US"
                    )
                  : 0),

              tooltip: "Sold Item Hanger Values Redeemed",
            },
          ],
        },
      ]);
      setLoading(false);
      setData(leaderboardResults);
      console.log("Here is leaderboard results ", leaderboardResults);
    }
  }, [leaderboardResults]);

  return (
    <div style={{ minHeight: "40vh" }}>
      {loading ? (
        <Spinner />
      ) : (
        <section>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <>
              <div className="lb-title large-text text-center bold-text">
                Closest Closet Total Impact Statistics
              </div>
              <div style={{ padding: "2rem" }}>
                <div
                  className="my-closet-banner-wrapper-con"
                  style={{
                    padding: "2rem",
                    minHeight: "auto",
                    borderRadius: 10,
                  }}
                >
                  <div className="my-closet-banner-wrapper background-text">
                    <div
                      className="banner-img-con banner-img-con2 background-text"
                      style={{ width: "200px" }}
                    >
                      <FaEarthAmericas size={"8.5em"} />

                      <h3
                        className="bold-text background-text"
                        style={{ margin: ".5rem auto", lineBreak: "anywhere" }}
                      >
                        Global Impact Score
                      </h3>
                      {/* <h5 className="">All over the world</h5> */}
                    </div>
                    {bannerObj.map((s) => (
                      <div className="gamification-col">
                        <div
                          data-tooltip-id={s.title}
                          className="gami-title bold-text h5"
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="icon"
                            style={{ marginRight: "1rem", lineHeight: "10px" }}
                          >
                            <MdBarChart size={"1.25em"} />
                          </div>{" "}
                          <div>{s.title}</div>
                        </div>
                        <ReactTooltip
                          className="tooltipCustom"
                          id={s.title}
                          place="top"
                          content={s.titleTooltip}
                        ></ReactTooltip>
                        {s.content.map((ss, ind) => (
                          <div
                            className="info-row"
                            data-tooltip-id={ss.paragraph}
                            style={{ marginTop: ind === 0 ? "2rem" : "0" }}
                          >
                            <div className="icon">{ss.icon}</div>
                            <div className="content">
                              <p>{ss.paragraph}</p>
                              <h3 className="background-text bold-text">
                                {ss.numInfo}
                              </h3>
                              <ReactTooltip
                                className="tooltipCustom"
                                id={ss.paragraph}
                                place="left"
                                content={ss.tooltip}
                              ></ReactTooltip>
                              {ss.subtitle &&
                              ss.subtitle.indexOf("-") === -1 ? (
                                <small>
                                  <span style={{ color: "green" }}>
                                    {ss.subtitle}
                                  </span>{" "}
                                  save vs. your membership fees
                                </small>
                              ) : (
                                <>{ind === 2 && <small>&nbsp;</small>}</>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div
                className="leaderboard-con"
                style={{ marginTop: 0, paddingTop: "1rem" }}
              >
                <div className="impact-score">
                  <h2
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      letterSpacing: 1,
                      marginBottom: "1rem",
                    }}
                    className="h4 bold-text background-text"
                  >
                    <FaEarthAmericas />
                    &nbsp; &nbsp;Environmental Impact Score Member
                    Leaderboard&nbsp; &nbsp;
                    <FaEarthAmericas />
                    {/* <FaEarthAmericas size={"1rem"} style={{ color: "green" }} /> */}
                  </h2>
                  <div
                    style={{
                      marginTop: ".5rem",
                      height: "150vh",
                      overflowY: "auto",
                      border: "1px solid var(--grey-color)",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="leaderboard-left">
                      {data.leaderboard?.map(
                        (item, ind) =>
                          item.rank <= 25 && (
                            <>
                              <div
                                className={`leader-row h5 ${
                                  classes[item.rank] && classes[item.rank]
                                }`}
                                key={ind}
                              >
                                <div className="rank">
                                  <span
                                    className="rank-icon"
                                    style={{ position: "absolute", left: 8 }}
                                  >
                                    {badges[item.rank] && badges[item.rank]}
                                  </span>{" "}
                                  {item?.rank}
                                  {suffix[item.rank] && suffix[item.rank]}
                                </div>
                                {item.rank <= 3 && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      left: "5rem",
                                      top: 5,
                                    }}
                                    className="user-icon"
                                  >
                                    <img
                                      width={"25px"}
                                      height={"25px"}
                                      style={{ borderRadius: "50%" }}
                                      src={
                                        item.user.userDetailsInfo.userIcon ||
                                        noClosetIconList[item.rank]
                                      }
                                    />
                                  </div>
                                )}
                                <Link
                                  to={
                                    userInfo && userInfo._id == item._id
                                      ? { pathname: "/user/my-closet" }
                                      : {
                                          pathname: `/closet/${
                                            item?.user?.userDetailsInfo
                                              ?.closetName
                                              ? item.user.userDetailsInfo
                                                  .closetName
                                              : item?.user?.userDetailsInfo
                                                  ?.userFName
                                              ? item.user.userDetailsInfo.userFName.includes(
                                                  "'s"
                                                )
                                                ? item.user.userDetailsInfo
                                                    .userFName
                                                : `${item.user.userDetailsInfo.userFName}'s Closet`
                                              : "Anonymous"
                                          }`,
                                          search: `?closet=${item?.user?.userMasterInfo?._id}`,
                                        }
                                  }
                                  state={{
                                    id: item?.user?.userMasterInfo?._id,
                                  }}
                                >
                                  <div className="member">
                                    {item?.user?.userDetailsInfo?.closetName
                                      ? item.user.userDetailsInfo.closetName
                                      : item?.user?.userDetailsInfo?.userFName
                                      ? item.user.userDetailsInfo.userFName.includes(
                                          "'s"
                                        )
                                        ? item.user.userDetailsInfo.userFName
                                        : `${item.user.userDetailsInfo.userFName}'s Closet`
                                      : "Anonymous"}
                                  </div>
                                </Link>
                                <div className="score" title="Ecocycle Score">
                                  {item?.user?.ecoCycleScoreNum}
                                </div>
                              </div>
                            </>
                          )
                      )}
                      {/* Example of the rows (You can dynamically map them from an array of data) */}
                      {/* Add more rows as needed */}
                    </div>
                  </div>
                </div>

                <div className="metrics">
                  {data?.mostPostedLastMonth?.length > 0 && (
                    <div className="metric">
                      <h3
                        className="h4 bold-text background-text"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          letterSpacing: 1,
                          marginBottom: "1rem",
                        }}
                      >
                        <BsFillSendArrowUpFill /> &nbsp; &nbsp;Most Items Posted
                        Last Month&nbsp; &nbsp;
                        <BsFillSendArrowUpFill />
                      </h3>
                      <div className="leaderboard">
                        <div
                          className={`leader-row h5`}
                          style={{ padding: "5px" }}
                        >
                          <div className="rank">&nbsp;</div>
                          <div
                            className="rank-icon"
                            style={{
                              position: "absolute",
                              left: "5rem",
                              top: 5,
                            }}
                          >
                            &nbsp;
                          </div>

                          <div className="member">&nbsp;</div>
                          <div
                            className="score small-text text-center"
                            style={{ width: "90px" }}
                          >
                            <em>Items Posted</em>
                          </div>
                          <div
                            className="score small-text text-center"
                            style={{ width: "90px" }}
                          >
                            <em>Hangers Earned</em>
                          </div>
                        </div>
                        {data?.mostPostedLastMonth?.map((item, ind) => (
                          <div
                            className={`leader-row h5 ${
                              classes[ind + 1] && classes[ind + 1]
                            }`}
                            key={ind}
                          >
                            <div className="rank">
                              <span
                                className="rank-icon"
                                style={{ position: "absolute", left: 8 }}
                              >
                                {badges[ind + 1] && badges[ind + 1]}
                              </span>{" "}
                              {ind + 1}
                              {suffix[ind + 1] && suffix[ind + 1]}
                            </div>
                            {ind + 1 <= 3 && (
                              <div
                                style={{
                                  position: "absolute",
                                  left: "5rem",
                                  top: 5,
                                }}
                                className="user-icon"
                              >
                                <img
                                  width={"25px"}
                                  height={"25px"}
                                  style={{ borderRadius: "50%" }}
                                  src={
                                    item.closetIcon || noClosetIconList[ind + 1]
                                  }
                                />
                              </div>
                            )}
                            <Link
                              to={
                                userInfo && userInfo._id == item.userMasterId
                                  ? { pathname: "/user/my-closet" }
                                  : {
                                      pathname: `/closet/${
                                        item?.closetName &&
                                        item.closetName !== ""
                                          ? item.closetName
                                          : item?.userName &&
                                            item.userName !== ""
                                          ? item.userName.includes("'s")
                                            ? item.userName
                                            : `${item.userName}'s closet`
                                          : "Anonymous"
                                      }`,
                                      search: `?closet=${item?.userMasterId}`,
                                    }
                              }
                              state={{ id: item?.userMasterId }}
                            >
                              <div className="member">
                                {item?.closetName && item.closetName !== ""
                                  ? item.closetName
                                  : item?.userName && item.userName !== ""
                                  ? item.userName.includes("'s")
                                    ? item.userName
                                    : `${item.userName}'s closet`
                                  : "Anonymous"}
                                {/* {ind > 0 && "Show"} */}
                              </div>
                            </Link>
                            <div
                              className="score"
                              title="Total Products Posted"
                            >
                              {item.totalProductsPosted}
                            </div>
                            <div
                              className="score"
                              title="Total Hangers Redeemed"
                            >
                              {item.totalHangersEarned}
                            </div>
                          </div>
                        ))}
                        {/* Example of the rows (You can dynamically map them from an array of data) */}
                        {/* Add more rows as needed */}
                      </div>
                    </div>
                  )}

                  {data?.mostBoughtLastMonth?.length > 0 && (
                    <div className="metric">
                      <h3
                        className="h4 bold-text background-text"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          letterSpacing: 1,
                          marginBottom: "1rem",
                        }}
                      >
                        <BsHandbagFill /> &nbsp; &nbsp;Most Items Bought Last
                        Month &nbsp; &nbsp;
                        <BsHandbagFill />
                      </h3>
                      <div className="leaderboard">
                        {data?.mostBoughtLastMonth?.map((item, ind) => (
                          <div
                            className={`leader-row h5 ${
                              classes[ind + 1] && classes[ind + 1]
                            }`}
                            key={ind}
                          >
                            <div className="rank">
                              <span
                                className="rank-icon"
                                style={{ position: "absolute", left: 8 }}
                              >
                                {badges[ind + 1] && badges[ind + 1]}
                              </span>{" "}
                              {ind + 1}
                              {suffix[ind + 1] && suffix[ind + 1]}
                            </div>
                            {ind + 1 <= 3 && (
                              <div
                                style={{
                                  position: "absolute",
                                  left: "5rem",
                                  top: 5,
                                }}
                                className="user-icon"
                              >
                                <img
                                  width={"25px"}
                                  height={"25px"}
                                  style={{ borderRadius: "50%" }}
                                  src={
                                    item.closetIcon || noClosetIconList[ind + 1]
                                  }
                                />
                              </div>
                            )}
                            <Link
                              to={
                                userInfo && userInfo._id == item.userMasterId
                                  ? { pathname: "/user/my-closet" }
                                  : {
                                      pathname: `/closet/${
                                        item?.closetName &&
                                        item.closetName !== ""
                                          ? item.closetName
                                          : item?.userName &&
                                            item.userName !== ""
                                          ? item.userName.includes("'s")
                                            ? item.userName
                                            : `${item.userName}'s closet`
                                          : "Anonymous"
                                      }`,
                                      search: `?closet=${item?.userMasterId}`,
                                    }
                              }
                              state={{ id: item?.userMasterId }}
                            >
                              <div className="member">
                                {item?.closetName && item.closetName !== ""
                                  ? item.closetName
                                  : item?.userName && item.userName !== ""
                                  ? item.userName.includes("'s")
                                    ? item.userName
                                    : `${item.userName}'s closet`
                                  : "Anonymous"}
                              </div>
                            </Link>
                            <div
                              className="score"
                              title="Total Products Bought"
                            >
                              {item.totalProductsBought}
                            </div>
                            <div
                              className="score"
                              title="Total Hangers Redeemed"
                            >
                              {item.totalHangersRedeemed}
                            </div>
                          </div>
                        ))}
                        {/* Example of the rows (You can dynamically map them from an array of data) */}
                        {/* Add more rows as needed */}
                      </div>
                    </div>
                  )}

                  {data?.mostSoldLastMonth?.length > 0 && (
                    <div className="metric">
                      <h3
                        className="h4 bold-text background-text"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          letterSpacing: 1,
                          marginBottom: "1rem",
                        }}
                      >
                        <MdSell /> &nbsp; &nbsp;Most Items Sold Last Month
                        &nbsp; &nbsp;
                        <MdSell />
                      </h3>
                      <div className="leaderboard">
                        {data?.mostSoldLastMonth?.map((item, ind) => (
                          <div
                            className={`leader-row h5 ${
                              classes[ind + 1] && classes[ind + 1]
                            }`}
                            key={ind}
                          >
                            <div className="rank">
                              <span
                                className="rank-icon"
                                style={{ position: "absolute", left: 8 }}
                              >
                                {badges[ind + 1] && badges[ind + 1]}
                              </span>{" "}
                              {ind + 1}
                              {suffix[ind + 1] && suffix[ind + 1]}
                            </div>
                            {ind + 1 <= 3 && (
                              <div
                                style={{
                                  position: "absolute",
                                  left: "5rem",
                                  top: 5,
                                }}
                                className="user-icon"
                              >
                                <img
                                  width={"25px"}
                                  height={"25px"}
                                  style={{ borderRadius: "50%" }}
                                  src={
                                    item.closetIcon || noClosetIconList[ind + 1]
                                  }
                                />
                              </div>
                            )}
                            <Link
                              to={
                                userInfo && userInfo._id == item.userMasterId
                                  ? { pathname: "/user/my-closet" }
                                  : {
                                      pathname: `/closet/${
                                        item?.closetName &&
                                        item.closetName !== ""
                                          ? item.closetName
                                          : item?.userName &&
                                            item.userName !== ""
                                          ? item.userName.includes("'s")
                                            ? item.userName
                                            : `${item.userName}'s closet`
                                          : "Anonymous"
                                      }`,
                                      search: `?closet=${item?.userMasterId}`,
                                    }
                              }
                              state={{ id: item?.userMasterId }}
                            >
                              <div className="member">
                                {item?.closetName && item.closetName !== ""
                                  ? item.closetName
                                  : item?.userName && item.userName !== ""
                                  ? item.userName.includes("'s")
                                    ? item.userName + " closet"
                                    : `${item.userName}'s closet`
                                  : "Anonymous"}
                              </div>
                            </Link>
                            <div className="score" title="Total Products Sold">
                              {item.commonProductCount.length}
                            </div>
                            <div
                              className="score"
                              title="Total Hangers Redeemed"
                            >
                              {item.hangersRedeemed}
                            </div>
                          </div>
                        ))}
                        {/* Example of the rows (You can dynamically map them from an array of data) */}
                        {/* Add more rows as needed */}
                      </div>
                    </div>
                  )}

                  {data?.mostPostedThisYear?.length > 0 && (
                    <div className="metric">
                      <h3
                        className="h4 bold-text background-text"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          letterSpacing: 1,
                          marginBottom: "1rem",
                        }}
                      >
                        <BsFillSendArrowUpFill /> &nbsp; &nbsp;Most Items Posted
                        This Year &nbsp; &nbsp; <BsFillSendArrowUpFill />
                      </h3>
                      <div className="leaderboard">
                        {data?.mostPostedThisYear?.map((item, ind) => (
                          <div
                            className={`leader-row h5 ${
                              classes[ind + 1] && classes[ind + 1]
                            }`}
                            key={ind}
                          >
                            <div className="rank">
                              <span
                                className="rank-icon"
                                style={{ position: "absolute", left: 8 }}
                              >
                                {badges[ind + 1] && badges[ind + 1]}
                              </span>{" "}
                              {ind + 1}
                              {suffix[ind + 1] && suffix[ind + 1]}
                            </div>
                            {ind + 1 <= 3 && (
                              <div
                                style={{
                                  position: "absolute",
                                  left: "5rem",
                                  top: 5,
                                }}
                                className="user-icon"
                              >
                                <img
                                  width={"25px"}
                                  height={"25px"}
                                  style={{ borderRadius: "50%" }}
                                  src={
                                    item.closetIcon || noClosetIconList[ind + 1]
                                  }
                                />
                              </div>
                            )}
                            <Link
                              to={
                                userInfo && userInfo._id == item.userMasterId
                                  ? { pathname: "/user/my-closet" }
                                  : {
                                      pathname: `/closet/${
                                        item?.closetName &&
                                        item.closetName !== ""
                                          ? item.closetName
                                          : item?.userName &&
                                            item.userName !== ""
                                          ? item.userName.includes("'s")
                                            ? item.userName
                                            : `${item.userName}'s closet`
                                          : "Anonymous"
                                      }`,
                                      search: `?closet=${item?.userMasterId}`,
                                    }
                              }
                              state={{ id: item?.userMasterId }}
                            >
                              <div className="member">
                                {item?.closetName && item.closetName !== ""
                                  ? item.closetName
                                  : item?.userName && item.userName !== ""
                                  ? item.userName.includes("'s")
                                    ? item.userName
                                    : `${item.userName}'s closet`
                                  : "Anonymous"}
                              </div>
                            </Link>
                            <div
                              className="score"
                              title="Total Products Posted"
                            >
                              {item.totalProductsPosted}
                            </div>
                            <div
                              className="score"
                              title="Total Hangers Redeemed"
                            >
                              {item.totalHangersEarned}
                            </div>
                          </div>
                        ))}
                        {/* Example of the rows (You can dynamically map them from an array of data) */}
                        {/* Add more rows as needed */}
                      </div>
                    </div>
                  )}

                  {data?.mostBoughtThisYear?.length > 0 && (
                    <div className="metric">
                      <h3
                        className="h4 bold-text background-text"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          letterSpacing: 1,
                          marginBottom: "1rem",
                        }}
                      >
                        <BsHandbagFill /> &nbsp; &nbsp;Most Items Bought This
                        Year &nbsp; &nbsp;
                        <BsHandbagFill />
                      </h3>
                      <div className="leaderboard">
                        {data?.mostBoughtThisYear?.map((item, ind) => (
                          <div
                            className={`leader-row h5 ${
                              classes[ind + 1] && classes[ind + 1]
                            }`}
                            key={ind}
                          >
                            <div className="rank">
                              <span
                                className="rank-icon"
                                style={{ position: "absolute", left: 8 }}
                              >
                                {badges[ind + 1] && badges[ind + 1]}
                              </span>{" "}
                              {ind + 1}
                              {suffix[ind + 1] && suffix[ind + 1]}
                            </div>
                            {ind + 1 <= 3 && (
                              <div
                                style={{
                                  position: "absolute",
                                  left: "5rem",
                                  top: 5,
                                }}
                                className="user-icon"
                              >
                                <img
                                  width={"25px"}
                                  height={"25px"}
                                  style={{ borderRadius: "50%" }}
                                  src={
                                    item.closetIcon || noClosetIconList[ind + 1]
                                  }
                                />
                              </div>
                            )}
                            <Link
                              to={
                                userInfo && userInfo._id == item.userMasterId
                                  ? { pathname: "/user/my-closet" }
                                  : {
                                      pathname: `/closet/${
                                        item?.closetName &&
                                        item.closetName !== ""
                                          ? item.closetName
                                          : item?.userName &&
                                            item.userName !== ""
                                          ? item.userName.includes("'s")
                                            ? item.userName
                                            : `${item.userName}'s closet`
                                          : "Anonymous"
                                      }`,
                                      search: `?closet=${item?.userMasterId}`,
                                    }
                              }
                              state={{ id: item?.userMasterId }}
                            >
                              <div className="member">
                                {item?.closetName && item.closetName !== ""
                                  ? item.closetName
                                  : item?.userName && item.userName !== ""
                                  ? item.userName.includes("'s")
                                    ? item.userName
                                    : `${item.userName}'s closet`
                                  : "Anonymous"}
                              </div>
                            </Link>
                            <div
                              className="score"
                              title="Total Products Bought"
                            >
                              {item.totalProductsBought}
                            </div>
                            <div
                              className="score"
                              title="Total Hangers Redeemed"
                            >
                              {item.totalHangersRedeemed}
                            </div>
                          </div>
                        ))}
                        {/* Example of the rows (You can dynamically map them from an array of data) */}
                        {/* Add more rows as needed */}
                      </div>
                    </div>
                  )}

                  {data?.mostSoldThisYear?.length > 0 && (
                    <div className="metric">
                      <h3
                        className="h4 bold-text background-text"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          letterSpacing: 1,
                          marginBottom: "1rem",
                        }}
                      >
                        <MdSell /> &nbsp; &nbsp;Most Items Sold This Year &nbsp;
                        &nbsp;
                        <MdSell />
                      </h3>
                      <div className="leaderboard">
                        {data?.mostSoldThisYear?.map((item, ind) => (
                          <div
                            className={`leader-row h5 ${
                              classes[ind + 1] && classes[ind + 1]
                            }`}
                            key={ind}
                          >
                            <div className="rank">
                              <span
                                className="rank-icon"
                                style={{ position: "absolute", left: 8 }}
                              >
                                {badges[ind + 1] && badges[ind + 1]}
                              </span>{" "}
                              {ind + 1}
                              {suffix[ind + 1] && suffix[ind + 1]}
                            </div>
                            {ind + 1 <= 3 && (
                              <div
                                style={{
                                  position: "absolute",
                                  left: "5rem",
                                  top: 5,
                                }}
                                className="user-icon"
                              >
                                <img
                                  width={"25px"}
                                  height={"25px"}
                                  style={{ borderRadius: "50%" }}
                                  src={
                                    item.closetIcon || noClosetIconList[ind + 1]
                                  }
                                />
                              </div>
                            )}
                            <Link
                              to={
                                userInfo && userInfo._id == item.userMasterId
                                  ? { pathname: "/user/my-closet" }
                                  : {
                                      pathname: `/closet/${
                                        item?.closetName &&
                                        item.closetName !== ""
                                          ? item.closetName
                                          : item?.userName &&
                                            item.userName !== ""
                                          ? item.userName.includes("'s")
                                            ? item.userName
                                            : `${item.userName}'s closet`
                                          : "Anonymous"
                                      }`,
                                      search: `${
                                        item?.closetName
                                          ? item.closetName
                                          : "Anonymous"
                                      }?closet=${item?.userMasterId}`,
                                    }
                              }
                              state={{ id: item?.userMasterId }}
                            >
                              <div className="member">
                                {item?.closetName && item.closetName !== ""
                                  ? item.closetName
                                  : item?.userName && item.userName !== ""
                                  ? item.userName.includes("'s")
                                    ? item.userName
                                    : `${item.userName}'s closet`
                                  : "Anonymous"}
                              </div>
                            </Link>
                            <div className="score" title="Total Products Sold">
                              {item.commonProductCount.length}
                            </div>
                            <div
                              className="score"
                              title="Total Hangers Redeemed"
                            >
                              {item.hangersRedeemed}
                            </div>
                          </div>
                        ))}
                        {/* Example of the rows (You can dynamically map them from an array of data) */}
                        {/* Add more rows as needed */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          </ScrollAnimation>
          {/* <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <div className="leaderboard-container">
            <h2 className="leaderboard-title">
              ClosestCloset Impact Score Leaderboard
            </h2>
            <div className="leaderboard-table site-width">
              {data.map((item, index) => (
                <div key={item.id} className="leaderboard-row">
                  <div className="tile rank-score">
                    #{item.rank} | {item?.user?.ecoCycleScoreNum} Impact Score
                  </div>
                  <div className="tile details">
                    {item?.user?.userDetailsInfo?.closetName || "Anonymous Closet"} | {item?.user?.itemPostedNum} Items Posted |{" "}
                    {item?.user?.itemPostedNum * 25} kg of CO2 Saved
                  </div>
                  <Link
                  to={
                    userInfo && userInfo._id == item._id
                      ? { pathname: "/user/my-closet" }
                      : {
                          pathname: `/closet/${item?.user?.userDetailsInfo?.closetName}`,
                          search: `?closet=${item?.user?.userMasterInfo?._id}`,
                        }
                  }
                  state={{ id: item?.user?.userMasterInfo?._id }}
                >
                  <button className="view-closet-btn">View Closet</button>
                </Link>
                </div>
              ))}
            </div>
          </div>
          </ScrollAnimation> */}
        </section>
      )}
    </div>
  );
};

export default Leaderboard;
