import React, { useEffect, useRef, useState } from "react";
import "./Founder.css";
import danielle from "../../assets/images/danielle.png";
import signupVideo from "../../assets/images/thankyousignuppage.mp4";
import ScrollToTop from "../../ScrollToTop";
import thankYouSignupVideo from "../../assets/videos/cc-thankyou-signup.mp4";
import { GoMute, GoUnmute } from "react-icons/go";
import { FaCirclePlay } from "react-icons/fa6";

const Founder = () => {
  const videoRef = useRef();

  // useEffect(() => {
  //   const handleScroll = () => {
  //     // Adjust this value based on when you want the video to start playing
  //     const scrollTrigger = window.innerWidth > 640 ? 750 : 300;
  //     const scrollTriggerEnd = window.innerWidth > 640 ? 1650 : 1000;
  //     // Get the current scroll position
  //     const scrollY = window.scrollY || window.pageYOffset;
  //     console.log(scrollY);

  //     // Check if the scroll position is greater than the trigger point
  //     if (scrollY > scrollTrigger && scrollY < scrollTriggerEnd) {
  //       // Play the video
  //       if (!videoRef?.current?.paused) videoRef?.current?.play();
  //     } else {
  //       // Pause the video if scrolling back up
  //       videoRef?.current?.pause();
  //     }
  //   };

  //   // Attach the scroll event listener
  //   window.addEventListener("scroll", handleScroll);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []); // Only run this effect once on component mount

  const VideoComponent = ({ layout, vref, vidInd, vidSrc }) => {
    const [pauseCurrentVideoIndex, setPauseCurrentVideoIndex] = useState(false);
    const [muteCurrentVideoIndex, setMuteCurrentVideoIndex] = useState(false);
    return (
      <div className={`signup-video-con`} style={{ position: "relative" }}>
        {pauseCurrentVideoIndex && (
          <div
            className="video-play-btn"
            onClick={() => {
              if (vref.current.paused) {
                console.log("played from button");
                vref.current.play();
                setPauseCurrentVideoIndex(false);
              } else {
                console.log("paused from button");
                setPauseCurrentVideoIndex(true);
                vref.current.pause();
              }
            }}
            style={{
              display: pauseCurrentVideoIndex ? "flex" : "none",
              top: "45%",
              right: "45%",

              zIndex: 999,
            }}
          >
            <FaCirclePlay size={"3em"} color="var(--primary-color)" />
            <span class="dot_animation"></span>
          </div>
        )}
        <div>
          <div
            onClick={() => {
              if (vref.current.paused) {
                console.log("played from surface");
                vref.current.play();
                setPauseCurrentVideoIndex(false);
              } else {
                console.log("paused from surface");
                setPauseCurrentVideoIndex(true);
                vref.current.pause();
              }
            }}
          >
            <video
              ref={vref}
              //     // loop={true}
              width="100%"
              height="100%"
              preload="auto"
              autoPlay={true}
              muted={false}
              style={{ borderRadius: "5px" }}
            >
              <source src={vidSrc}></source>
            </video>
          </div>
          {!pauseCurrentVideoIndex && (
            <div
              id="muteUnmuteBtn"
              style={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
                cursor: "pointer",
                background: "#fff",
                borderRadius: "50%",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                padding: "8px",
                display: "flex",
                zIndex: 1,
              }}
              role="button"
              onClick={() => {
                if (vref.current.muted) {
                  setMuteCurrentVideoIndex(false);
                  vref.current.muted = false;
                } else {
                  setMuteCurrentVideoIndex(true);
                  vref.current.muted = true;
                }
              }}
            >
              {muteCurrentVideoIndex ? (
                <GoMute color="var(--primary-color)" />
              ) : (
                <GoUnmute color="var(--primary-color)" />
              )}
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="founder_container h5">
      <img src={danielle} alt="Founder" />
      <div className="quote grey-text ">
        “I created Closest Closet to extend the life of fashion and reduce the
        amount of textile waste in landfills. Closest Closet empowers an
        ever-changing wardrobe without expense, waste, or guilt.”
      </div>
      <div
        className="founder_position background-text bold-text"
        style={{ marginTop: "20px" }}
      >
        ~ Danielle, Founder of Closest Closet
      </div>
      {window.location.href.includes("signup") && (
        <VideoComponent
          vidInd={1}
          vidSrc={thankYouSignupVideo}
          vref={videoRef}
        />
        // <div className="signup-video-con">
        //   <video
        //     ref={videoRef}
        //     // loop={true}
        //     width="100%"
        //     height="100%"
        //     preload="auto"
        //     controls={true}
        //     autoPlay={window.scrollY > 800 && true}
        //     muted={false}
        //     style={{ borderRadius: "5px" }}
        //   >
        //     <source src={thankYouSignupVideo}></source>
        //   </video>
        // </div>
      )}
    </div>
  );
};

export default Founder;
