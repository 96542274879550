import React, { useEffect, useState } from "react";
import {
  ChatEngineWrapper,
  ChatSocket,
  ChatFeed,
  ChatList,
} from "react-chat-engine";
import { useParams } from "react-router-dom";
import { getuserInfo } from "../../StateSlices/getuserSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "./SeparateChat.css";
import Spinner1 from "../../Spinner1/Spinner1";
const SeparateChat = () => {
  const [user, setUser] = useState(null);
  const [chat, setChat] = useState(null);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { receiverUserEmail, productId } = useParams();
  const { userInfo } = useSelector((state) => state.user);
  const senderUserEmail = userInfo.userDetailsId.userEmail;
  //consolele.log("sr", senderUserEmail, receiverUserEmail);

  useEffect(() => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(getuserInfo({ token: token }));
      window.scrollTo(0, 0);
    } else {
      navigate("/login", { replace: true });
    }
  }, []);

  function getOrCreateUser(callback) {
    // //consolele.log("PROPS", props);
    axios
      .put(
        "https://chatengine.eastus.cloudapp.azure.com/users/",
        {
          username: senderUserEmail,
          secret: senderUserEmail,
        },
        { headers: { "Private-Key": process.env.REACT_APP_CE_PRIVATE_KEY } }
      )
      .then((r) => callback(r.data));
  }
  function getOrCreateChat(callback) {
    // //consolele.log("inside ", senderUserEmail, receiverUserEmail);
    axios
      .put(
        "https://chatengine.eastus.cloudapp.azure.com/chats/",
        {
          usernames: [receiverUserEmail, senderUserEmail],
          is_direct_chat: true,
          title: productId,
        },
        {
          headers: {
            "Project-ID": process.env.REACT_APP_CE_PROJECT_ID,
            "User-Name": senderUserEmail,
            "User-Secret": senderUserEmail,
          },
        }
      )
      .then((r) => {
        setLoading(false);
        callback(r.data);
      });
  }

  useEffect(() => {
    setLoading(true);
    if (senderUserEmail && receiverUserEmail && productId) {
      getOrCreateUser((user) => {
        //consolele.log("uuser", user);
        setUser(user);
        getOrCreateChat((chat) => {
          // //consolele.log("CHHAHAAT", chat);
          setChat(chat);
        });
      });
    }
  }, [senderUserEmail, receiverUserEmail]);
  //consolele.log("CHATTRI", chat);
  if (chat) {
    var otherPerson = chat.people.find(
      (person) => person.person.username !== userInfo.userDetailsId.userEmail
    );
  }
  return (
    // <span>Hello</span>
    <>
      {!loading && chat != null ? (
        <div className="ind-chat-con">
          <ChatEngineWrapper>
            <ChatSocket
              projectID={process.env.REACT_APP_CE_PROJECT_ID}
              chatID={chat.id}
              chatAccessKey={chat.access_key}
              senderUsername={senderUserEmail}
            />
            <div className="chat-container-sep">
              <ChatFeed
                renderChatHeader={() => (
                  <div class="ce-chat-title-text h4" style={{}}>
                    {`${
                      otherPerson.person.first_name +
                      " " +
                      otherPerson.person.last_name
                    }`}
                    <div
                      className="h6 text-center grey-text"
                      style={{ margin: ".5rem auto", fontWeight: "normal" }}
                    >
                      -&nbsp;{otherPerson.person.username}
                    </div>
                  </div>
                )}
                activeChat={chat.id}
              />
            </div>
          </ChatEngineWrapper>
        </div>
      ) : (
        <Spinner1 style={{ minHeight: "100vh" }} />
      )}
    </>
  );
};

export default SeparateChat;
